import React from 'react';
// Customizable Area Start
import { Avatar, Box, Typography, Button } from '@material-ui/core';
export interface ViewJobPostProps {
  jobData: any;
}

const ViewJobPost: React.FC<ViewJobPostProps> = ({ jobData }) => {
  // Render the job post details and activity
  return (
    <>
    <Box sx={{ display: 'grid' }}>
      <Typography style={webStyle.jobTitleText}>Details</Typography>

      <Box sx={{ display: 'grid' }} style={webStyle.detailsConainer}>
        <Typography style={webStyle.headingText}> Job title:</Typography>
        <Typography style={webStyle.content as React.CSSProperties}> {jobData.name}</Typography>
        <div style={webStyle.devider} />

        <Typography style={webStyle.headingText}>
          Project Description:
        </Typography>
        <Typography style={webStyle.content as React.CSSProperties}> {jobData.description}</Typography>
        <div style={webStyle.devider} />

        <Typography style={webStyle.headingText}> Project Details:</Typography>
        <Box style={webStyle.timeLineGrid}>
          <div style={webStyle.Flex}>
            <Avatar style={webStyle.avatar}>1</Avatar>

            <Box style={webStyle.timeLineContent}>
              <Typography style={{...webStyle.headingText,marginBottom:"0px"}}>{'Scope'}</Typography>
              <Typography style={webStyle.subHeadingText}>
                {jobData?.estimate},{jobData?.project_length}
              </Typography>
            </Box>
          </div>
          <div style={webStyle.Flex}>
            <Avatar style={webStyle.avatar}>2</Avatar>
            <Box style={webStyle.timeLineContent}>
              <Typography style={{...webStyle.headingText,marginBottom:"0px"}}>
                {'Budget'}
              </Typography>
              <Typography style={webStyle.subHeadingText}>
              $ {jobData.per_day_charge}/ {'project'}
              </Typography>
            </Box>
          </div>
          <div style={webStyle.Flex}>
            {jobData.patents.map(
              (
                patent: {
                  id: any;
                  categories: {
                    name: React.ReactNode;
                    sub_categories: Array<{
                      name: string;
                      sub_category_menus: Array<{
                        title: string;
                        sub_category_sub_menus: Array<{
                          title: string;
                          description: string;
                        }>;
                      }>;
                    }>;
                  };
                },
                patentIndex: number
              ) => (
                <React.Fragment key={patent.id || patentIndex}>
                  <Avatar style={webStyle.avatar}>{patentIndex + 1}</Avatar>
                  <Box style={webStyle.timeLineContent}>
                    <Typography style={{ ...webStyle.headingText, marginBottom: "0px" }}>
                      {patent.categories.name}
                    </Typography>
                    <Typography style={webStyle.subHeadingText}>
                      {patent.categories.sub_categories
                        .map(subCategory => subCategory.name)
                        .join(", ")}
                    </Typography>
                    {patent.categories.sub_categories.map((subCategory, subCatIndex) => (
                      <Box key={subCatIndex} 
                      style={webStyle.subHeadingText}
                      >
                        <Typography style={{...webStyle.subHeadingText,marginBottom:"5px"}}>
                          {subCategory.sub_category_menus
                            .map(menu => menu.title)
                            .join(", ")}
                        </Typography>
                        {subCategory.sub_category_menus.map((menu, menuIndex) => (
                          <Box key={menuIndex} 
                          >
                            <Typography 
                            style={webStyle.subHeadingText}
                            >
                              {menu.sub_category_sub_menus
                                .map(subMenu => `${subMenu.title} (${subMenu.description})`)
                                .join(", ")}
                            </Typography>
                          </Box>
                        ))}
                      </Box>
                    ))}
                  </Box>
                </React.Fragment>
              )
            )}
          </div>


        </Box>
        <div style={webStyle.devider} />
        <Typography style={webStyle.headingText}>
          Activity on this job:
        </Typography>
        <Box sx={{ display: 'grid' }} style={webStyle.detailsConainer}>
          <Box style={webStyle.activityItem}>
            <Typography style={webStyle.subHeadingText}>Proposals:</Typography>
            <Typography style={webStyle.content as React.CSSProperties}>{'10 To 15 '}</Typography>
          </Box>
          <Box style={webStyle.activityItem}>
            <Typography style={webStyle.subHeadingText}>
              Last viewed by the client:
            </Typography>
            <Typography style={webStyle.content as React.CSSProperties}>{'10 min ago'}</Typography>
          </Box>
          <Box style={webStyle.activityItem}>
            <Typography style={webStyle.subHeadingText}>
              Invite Sends:
            </Typography>
            <Typography style={webStyle.content as React.CSSProperties}>{'0'}</Typography>
          </Box>
          <Box style={webStyle.activityItem}>
            <Typography style={webStyle.subHeadingText}>
              Unanswered invites:
            </Typography>
            <Typography style={webStyle.content as React.CSSProperties}>{'0'}</Typography>
          </Box>

          <div style={webStyle.devider} />
          <Box style={webStyle.timeLineContent}>
            <Typography style={webStyle.headingText}>
              {'Skills and expertise:'}
            </Typography>
            <div style={webStyle.skills}>
              {jobData?.skills?.slice(0, 5)?.map((item: any) => (
                <Button
                  variant="contained"
                  color="primary"
                  style={webStyle.button}
                  fullWidth={true}
                >
                  {item}
                </Button>
              ))}
              {jobData?.skills?.length > 5 ? jobData?.skills?.length - 5 : ''}
            </div>
          </Box>

          <div style={webStyle.devider} />
          <Box style={{display:"flex",gap:"10px"}}>
            {jobData?.files && jobData.files.length > 0 ? (
              jobData.files.map((file: any, index: number) => (
                <Box
                  key={index}
                  style={webStyle.attactmentStyle}>
                 
                    {file.name}
                </Box>
              ))
            ) : (
              <Typography>No files attached</Typography>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
   </>
  );
};
export default ViewJobPost;

const webStyle = {
  detailsConainer: {
    background: '#F7F9FA',
    padding: '15px',
    display: ''
  },
  avatar: {
    width: '31px',
    height: '31px',
    flexShrink: 0,
    background: '#206FC4',
    marginRight: '9px'
  },
  attactmentStyle:{
    display:"flex",
    fontFamily: 'Rubik',
    border:"1px solid #206FC4",
    borderRadius:"8px",
    background: "#206FC4",
    padding:"10px",
    color:"white",
    fontSize: '14px',
    fontWeight: 400,
},
  devider: {
    margin: '20px 0px',
    border: '1px solid #E4E5E6'
  },
  jobTitleText: {
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '19px',
    letterSpacing: '0px',
    textalign: 'left',
    color: '#000000',
    marginBottom: '9px'
  },
  headingText: {
    fontFamily: 'Rubik',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '19px',
    letterSpacing: '0px',
    textalign: 'left',
    color: '#000000',
    marginBottom: '20px'
  },
  subHeadingText: {
    fontFamily: 'Rubik',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '16.94px',
    letterSpacing: '0px',
    textalign: 'left',
    color: '#515152',
    width: '280px'
  },
  content: {
    fontFamily: 'Rubik',
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '17px',
    letterSpacing: '0px',
    textalign: 'left',
    wordBreak:"break-all"
  },
  activityItem: {
    display: 'flex',
    marginBottom: '10px'
  },
  timeLineGrid: {
    display: 'grid',
    gridAutoFlow: 'column',
    gridGap: '50px'
  },
  timeLineContent: {
    display: 'grid',
    gridAutoFlow: 'row',
    rowGap: '6px'
  },
  Flex: {
    display: 'flex',
    alignItems: 'center'
  },
  skills: {
    display: 'flex',
    flexDirecrion: 'column',
    alignItems: 'center',
    marginTop: '13px'
  },
  button: {
    marginRight: '8px',
    maxWidth: 'max-content'
  },
  lowerSectionBtn: {
    maxWidth: "161px",
    maxHeight: "55px",
    backgroundColor: "#206FC4",
    borderRadius: "10px",
  },
  btnText: {
    fontFamily: 'Rubik',
    fontSize: "16px",
    color: "#FFFFFF",
    padding: "15px",
    lineHeight: "27px",
    textDecoration: "none",
  },
};
// Customizable Area End
