// Customizable Area Start
import { Box, Button, CircularProgress, Grid, TextField, Typography, styled } from "@material-ui/core";
import React from "react";
import DashboardController, { webConfigJSON } from "../DashboardController.web";
import {attactfile} from "../assets"
import { ellipse } from "./assets";
import { DeleteOutlined } from "@material-ui/icons";
const CustomTypography = styled(Typography)({
  fontFamily:"Rubik",
  fontSize: "16px",
  fontWeight: "bold",
  color: "#FFFFFF",
  padding: "15px",
  textDecoration: "none",
  cursor:"pointer",
  background: '#364BA0',
  borderRadius: 10,
  textAlign:"center",
  width:"100%",
  maxWidth: "161px",
  maxHeight: "55px",
  '&:hover': {
    color:"#364BA0",
    border:"1px solid #364BA0",
    backgroundColor: "#fff",
  }
})
const CustomBox = styled(Box)({
    marginTop: "20px",
    maxWidth: "1384px",
    margin: "0 auto",
  "@media (max-width: 950px)": {
  display: "flex",
  justifyContent: "center",
  margin:"auto",
  // width:"100%",
  paddingInline: "12px"
},
})
const CustomBtnBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  position: 'absolute',
  bottom: 20,
  left: 0,
  width: '100%',
  "@media (max-width: 950px)": {
    display: 'flex',
    justifyContent: 'space-between',
     position: 'unset',
    alignItems: 'center',
    width: '100%',
    paddingBottom:"30px",
    paddingTop:"20px"
},
})
// Customizable Area End
export default class StartConversation extends DashboardController {
  render() {
    //Customizable Area Start
    const {loading} = this.props
    return (
      <>
        <CustomBox>
          <Grid
            container
            spacing={2}
            style={styles.gridContainer as React.CSSProperties}
          >
            <Grid item xs={12} md={5}>
              <Typography style={styles.heading as React.CSSProperties}>
                Last step, start the conversation
              </Typography>
              <Typography style={styles.paraghraph as React.CSSProperties}>
                When searching for an IP professional, It’s important to clearly
                specify the specific job you have in mind. This will help you
                attract the right candidates who have the expertise and
                experience necessary for the job. Be sure to clearly state the
                job title, such as “parent attorney” or “trademark paralegal” as
                well as any required qualifications, experience, or skills. You
                may also want to attach relevant documents, such as a job
                description or patent application, to give candidates a better
                understanding of the role. Example of specific jobs include
                “patent agent to help draft and file patent applications for a
                new medical device , in usa” or “trademark attorney to help us
                protect our brand in the cosmetics industry in Berlin” By being
                clear and specific, you can attract the right IP professionals
                and increase your chances of success.
              </Typography>
            </Grid>
            <Grid item xs={12} md={5}>
              <Typography style={styles.leftHand as React.CSSProperties}>
                Add a title
              </Typography>
              <Typography style={styles.leftTypography as React.CSSProperties}>
              {this.props.name}
              </Typography>
              <Typography style={styles.descYourJob as React.CSSProperties}>
                Describe your job
              </Typography>
              <Box style={styles.textArea as React.CSSProperties}>
              <TextField minRows={11} variant="outlined" multiline style={{width:"100%",minWidth:"auto"
               }}
                data-testid="conversation"
                value={this.props.description}
              onChange={(event:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>)=>{this.props.getDescriptionSet&& this.props.getDescriptionSet(event.target.value)}}  error={this.props.describeError} 
              helperText={this.props.describeError && `${webConfigJSON.errorMessage}`} />
              </Box>
              <Typography style={styles.attachFile as React.CSSProperties}>
              <div style={styles.attachBtn as React.CSSProperties} className="your-button-class">
                    <label htmlFor="fileInput" style={{ display: "flex", alignItems: "center" }}>
                        <img alt="attachfile" src={attactfile} style={{ marginRight: "8px" }} />
                        <p style={{ maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
                            {this.props.files?.length > 0
                                ? `Attached Files: ${this.props.files.length}`
                                : "Attach File"}
                        </p>
                    </label>
                    <input
                        type="file"
                        id="fileInput"
                        style={{ display: "none" }}
                        data-test-id="handleFileChange"
                        accept=".pdf,.jpeg,.jpg,.png,.doc,.docx,.txt"
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.props.handleFileChange(e.target.files)}
                        multiple
                    />
                </div>

                {this.props.fileError && (
                    <span style={{ color: "red", display: "flex", fontSize: "15px", margin: "5px 2px 2px 10px" }}>
                        *{this.props.fileError}
                    </span>
                )}


                <span style={styles.maxFile as React.CSSProperties}>(You may attach up to 5 files under the size of 5 MB each)</span>
              </Typography>
              {this.props.files?.length > 0 && (
                  <div
                      style={{
                          margin: "10px 0",
                          display: "flex",
                          flexWrap: "wrap",
                          gap: "10px",
                          padding: "10px",
                          borderRadius: "4px",
                          backgroundColor: "#f9f9f9",
                      }}
                  >
                      {this.props.files?.map((file: any, index: number) => (
                          <div
                              key={index}
                              style={{
                                  borderRadius: "26px",
                                  padding: "8px 12px",
                                  display: "flex",
                                  justifyContent:"space-between",
                                  backgroundColor: "#D2E2F3",
                                  minWidth: "100px",
                                  paddingBlock:"10px",
                                  paddingInline:"12px",
                                  zIndex:1
                              }}
                          >
                              <span
                                  style={{
                                      maxWidth: "120px",
                                      overflow: "hidden",
                                      textOverflow: "ellipsis",
                                      whiteSpace: "nowrap",
                                      color: "#364BA0",
                                      fontWeight: 400,
                                      fontFamily:"Rubik",
                                      fontSize:"12px",
                                      display:"contents"
                                  }}
                              >
                                  {file.name}
                              </span>
                                  <DeleteOutlined width={32} height={32} 
                                  style={{ color: "red" }} 
                                  onClick={() => this.props.handleRemoveFile && this.props.handleRemoveFile(file.name)}/>
                          </div>
                      ))}
                  </div>
              )}

            </Grid>
          </Grid>
            <img src={ellipse} alt="ellipse" style={styles.ellipseImg}/>
        </CustomBox>
          <CustomBtnBox>
              <Box style={styles.lowerSectionDiv2}>
              <Button
                 style={{
                  ...styles.lowerSectionItem1Text,
                  ...(this.state.isHovered && styles.lowerSectionItem1TextHovered),
                }}
                data-test-id = "handleConversationBack"
                onClick={() => this.handleConversationBack()}
                onMouseEnter={this.handleHover}
                onMouseLeave={this.handleLeave}
              >
                BACK
              </Button>
              {loading ? ( 
                <Box display="flex" alignItems="center" justifyContent="center">
                <CircularProgress size={24} /> {/* Replace with spinner */}
                </Box>              
                ) : (
                <CustomTypography
                  data-testid="roadMap"
                  onClick={() => this.props.getRoadMap && this.props.getRoadMap()}
                >
                  Review this post
                </CustomTypography>
              )}
              </Box>
          </CustomBtnBox>
      </>
    );
    //Customizable Area End
  }
}

// Customizable Area Start
const theme = {
  spacing: {
    2: "8px",
  },
  breakpoints: {
    down: (size: any) => `@media (max-width: ${size}px)`,
  },
};
const styles = {
  root: {
    
  },
  gridContainer: {
    justifyContent: "center",
    gridGap: "20px",
    marginTop:"40px"
  },
  heading: {
    width: " 348px",
    height: "116px",
    fontSize: "38px",
    lineHeight: "58px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#000000",
    fontWeight: 500,
    fontFamily:"Rubik",
  },
  headingInvento: {
    width: " 348px",
    height: "116px",
    fontSize: "36px",
    lineHeight: "58px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#000000",
    fontWeight: 400,
    fontFamily:"Rubik",
  },
  paraghraph: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "29px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#5C5C5C",
    fontFamily:"Rubik",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  leftHand: {
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "23px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#575757",
    marginLeft: "9px",
    fontFamily:"Rubik",
  },
  leftTypography: {
    width: " 432px",
    height: "46px",
    padding: "10px",
    border: " 1px solid #D9D9D9",
    borderRadius: "5px",
    fontSize: "17px",
    fontWeight: 400,
    lineHeight: "23px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#000000",
    marginTop: "15px",
    marginLeft: "10px",
    fontFamily:"Rubik",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
      height: "20%",
    },
  },
  descYourJob: {
    marginTop: "10px",
    fontSize: "19px",
    fontWeight: 400,
    lineHeight: "23px",
    letterSpacing: "0px",
    textAlign: "left",
    color: "#575757",
    marginLeft: "9px",
    fontFamily:"Rubik",
  },
  attachFile: {
    marginTop: "30px",
    alignItems: "center",
    display:"flex"
  },
  textArea: {
    marginTop: "5px",
    overflow:"auto",
    width: "454px",
    height: "256px",
    color: "#000000",
    padding:"10px",
    fontFamily:"Rubik",
    borderRadius: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "95%",
    },
  },
  attachBtn: {
    background: "#D2E2F3",
    color: "#364BA0",
    borderRadius: "20px",
    fontSize: "12px",
    fontWeight: 500,
    lineHeight: "23px",
    letterSpacing: "0px",
    textAlign: "left",
    marginLeft: "10px",
    fontFamily:"Rubik",
    padding: "0px 20px 0px 20px",
    justifycontent: "center",
    display: "flex",
  },
  maxFile: {
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "15px",
    letterSpacing: "0px",
    textAlign: "left",
    margin: "12px",
    color:"#535353",
    fontFamily:"Rubik",
  },
  btnbox: {
    alignItems: "center",
  },
  btnBack: {
    width: "197px",
    height: "55px",
  },
  lowerSectionDiv2:{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingInline: 20,
    width: '100%'
  },
  bottomBackBtn: {
    width: "161px",
    height: "55px",
    fontSize: "16px",
    color: "#707070",
    fontFamily:"Rubik",
  },
  bottomBackConti: {
    width: "161px",
    height: "55px",
    fontSize: "16px",
    background: "#364BA0",
    borderRadius: "10px",
    color: "#FFFFFF",
    marginRight: "20px",
    marginTop: "-20px",
    cursor: "pointer",
    fontWeight:"bold",
    fontFamily:"Rubik",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "250px",
      marginTop: "-65px",
    },
  },
  ellipseImg:{
    position:'absolute' as 'absolute',
    bottom:'0',
    left:'0',
    width:'370px',
    zIndex : -1
  },
  lowerSectionItem1Text: {
    fontFamily: "Rubik" as "Rubik",
    fontSize: "16px",
    color: "#535353",
    lineHeight: "27px",
    cursor: "pointer" as "pointer",
    fontWeight: "bold" as "bold",
    position:"relative" as "relative",
    zIndex : 1
  },
lowerSectionItem1TextHovered:{
  color :""
},
};
// Customizable Area End
