import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import { ChangeEvent } from 'react';
import storage from "../../../framework/src/StorageProvider";

// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  months: string;
  open: any;
  reason: any;
  savioServiceFee: any;
  projectBudget: any;
  selectedDate: any;
  youReceive: any;
  authToken:any;
  message:any;
  proposalId:any;
  uploadedResume:any;
  text: string;
  describeYourExp: any;
  jobId: any;
  submitProposalRes:object;
  files: any[];
  jobDetailsData:any;
  milestones:any [],
  errors: any;
  selectedPaidOption: string,
  isDialogOpen: boolean,
  showFullContent: boolean
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SubmitProposalsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  withdrawProposalsId:any;
  submitProposalsId:any;
  fetchCurrentProposalsJobDetailsId:any
  maxLength: any;

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    console.disableYellowBox = true;
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.withdrawProposalsId="";
    this.submitProposalsId="";
    this.fetchCurrentProposalsJobDetailsId="";
    this.maxLength = 400;
    this.state = {
      months: '',
      showFullContent: false,
      open: false,
      reason: '',
      savioServiceFee: '5',
      projectBudget: '',
      selectedDate: '',
      youReceive: '',
      authToken: '',
      message:"",
      proposalId:"24",
      uploadedResume:"",
      text: '',
      describeYourExp:"",
      jobId:"",
      submitProposalRes:{},
      files: [],
      jobDetailsData:{},
      milestones: [
        { id: 1, description: '', date: '', amount: '', },
      ],
      errors: {},
      selectedPaidOption: 'milestone',
      isDialogOpen: false,
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start

    this.setState({
      authToken: await storage.get("authToken"),
    })
    this.setState({jobId:await storage.get('currentProposalJobId')});
    this.fetchCurrentProposalsJobDetails()

    // Customizable Area End
  }

  // Customizable Area Start
  validateMilestone(milestone: any) {
    const errors: any = {};
    const currentDate = new Date(); // Current date
  
    // Description validation
    if (!milestone.description.trim()) {
      errors.description = "*Please enter a description";
    } else if (milestone.description.length > 20) {
      errors.description = "*Please enter only 20 characters max";
    }
  
    // Date validation
    if (!milestone.date.trim()) {
      errors.date = "*Please enter a date";
    } else {
      const milestoneDate = new Date(milestone.date);
      currentDate.setHours(0, 0, 0, 0); 
      if (milestoneDate < currentDate) {
        errors.date = "*Date cannot be in the past";
      }
    }
  
    // Amount validation
    if (!milestone.amount.trim() || isNaN(Number(milestone.amount))) {
      errors.amount = "*Please enter an amount";
    }
  
    return errors;
  }
  handleJobPost = () => {
    this.props.navigation.navigate('SearchJob')
  }

  handleMonths = (event: any) => {
    this.setState({ months: event.target.value });
    
  };

  validateForm() {
    const errors: Record<string, any> = {};
    // Validate each milestone
    this.state.milestones.forEach((milestone: any) => {
      const milestoneErrors = this.validateMilestone(milestone);
      if (Object.keys(milestoneErrors).length > 0) {
        errors[milestone.id] = milestoneErrors;
      }
    });
    const resumeError = this.validateResume(this.state.files)
    if (resumeError) {errors.resume = resumeError}

    const coverLetterError = this.validateCoverLetter(this.state.text);
    if (coverLetterError) {errors.coverLetter = coverLetterError}

    const bidError = this.validateBid(this.state.projectBudget);
    if (bidError) {errors.bid = bidError}
    this.setState({ errors })
    return Object.keys(errors).length === 0
}
  

  viewProposals=()=>{
    this.props.navigation.navigate("FullWidthTabProposals")
    this.setState({ 
      
      
      isDialogOpen: false });
  }
  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  toggleContent = () => {
    this.setState(prevState => ({
      showFullContent: !prevState.showFullContent,
    }));
  };
  
  handleHome = () => {
    this.setState({ isDialogOpen: false });
    this.props.navigation.navigate("SearchJob")
  }
  handleClickOpen = () => {
    this.props.navigation.navigate('SendProposals')
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleChange = (event: any) => {
    const { name, value } = event.target;
    this.setState(({
      [name]: value
    } as unknown) as Pick<S, keyof S>);

  };
  handleProjectBudget = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const numericRegex = /^\d+(?:\.\d*)?$/;
  
    if (numericRegex.test(value) || value === '') {
      const numericValue = parseFloat(value);
      const commission = isNaN(numericValue) ? 0 : numericValue * 0.05;
      const remainingAmount = isNaN(numericValue) ? '' : (numericValue - commission).toFixed(2);
  
      this.setState({
        projectBudget: value,
        youReceive: remainingAmount,
        errors: {
          ...this.state.errors,
          bid: '', 
        },
      });
    } else {
      this.setState({
        errors: {
          ...this.state.errors,
          bid: '*Please enter a bid amount',
        },
      });
    }
  }; 
  
  handleMilestoneChange(id: string | number, field: string, value: string) {
    const milestones = [...this.state.milestones];
    const milestoneIndex = milestones.findIndex(m => m.id === id);
    milestones[milestoneIndex][field] = value;
  
    const errors = { ...this.state.errors };
    errors[id] = this.validateMilestone(milestones[milestoneIndex]);
  
    this.setState({ milestones, errors });
  }

  
  handleWithdrawProposals = () => {
    const withdrawProposals = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": configJSON.apiContentType,
     
    };

    const body = {
      proposals: {
        reason: this.state.reason,
        message: this.state.message
      },

      token: this.state.authToken
    };
    this.withdrawProposalsId = withdrawProposals.messageId;
    withdrawProposals.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.withdrawProposalsEndpoint}/${this.state.proposalId}/withdraw_proposal`
    );
    withdrawProposals.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    withdrawProposals.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(body)
    )
    withdrawProposals.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    );
    runEngine.sendMessage(withdrawProposals.id, withdrawProposals);

  }

    
  fetchCurrentProposalsJobDetails=()=>{
    const currentProposalsJobDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    ); 
    const header = {
      "token": this.state.authToken, 
      'Content-Type': configJSON.dashboarContentType,
    };
    this.fetchCurrentProposalsJobDetailsId = currentProposalsJobDetails.messageId;

    currentProposalsJobDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchCurrentProposalsJobDetailsEndPoint+`${this.state.jobId}`
    );

    currentProposalsJobDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    currentProposalsJobDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(currentProposalsJobDetails.id, currentProposalsJobDetails);
  };


  handleAddMilestone = () => {
    const { milestones } = this.state;
    const newMilestone = {
      id: milestones.length + 1,
      description: '',
      date: '',
      amount: '',
    };
    this.setState({ milestones: [...milestones, newMilestone] });
  };
  
  handleRemoveMilestone = (id: any) => {
    const { milestones } = this.state;
    const updatedMilestones = milestones.filter((milestone: { id: any; }) => milestone.id !== id);
    this.setState({ milestones: updatedMilestones });
  };

  handleTextChange = (e:any) => {
    const text = e.target.value;
    const coverLetterError = this.validateCoverLetter(text);
    this.setState({
      text,
      errors: {
        ...this.state.errors,
        coverLetter: coverLetterError,
      },
    });
  };
  

  validateCoverLetter (coverLetter: string) {
    if (!coverLetter.trim()) {
      return "*Please enter a cover letter";
    }
    if (coverLetter.length < 20) {
      return "Cover letter must be at least 20 characters";
    }
    return ""
  }


handlePaidOptionChange = (event: { target: { value: string; }; }) => {
  this.setState({ selectedPaidOption: event.target.value });
};

  validateProjectForm() {
    const errors: Record<string, any> = {};

    const coverLetterError = this.validateCoverLetter(this.state.text);
    if (coverLetterError) {
      errors.coverLetter = coverLetterError;
    }

    const bidError = this.validateBid(this.state.projectBudget);
    if (bidError) {
      errors.bid = bidError;
    }

    const submissionDateError = this.validateSubmission(this.state.selectedDate);
    if (submissionDateError) {
      errors.submissionError = submissionDateError;
    }

    const resumeError = this.validateResume(this.state.files);
    if (resumeError) {
      errors.resume = resumeError;
    }
    this.setState({ errors });

    return Object.keys(errors).length === 0;
  }

  validateBid(bid: string) {
    if (!bid.trim()) {
      return "*Please enter a bid amount";
    }
    if (isNaN(Number(bid)) || Number(bid) <= 0) {
      return "Enter a valid bid amount";
    }
    return "";
  }

  validateSubmission(date: string) {
    if (!date.trim()) {
      return "*Please enter a submission date";
    }
  
    const selectedDate = new Date(date);
    const today = new Date();
  
    today.setHours(0, 0, 0, 0);
  
    if (selectedDate < today) {
      return "*Submission date cannot be in the past";
    }
  
    return "";
  }  
  
  handleSubmit = () => {
    if (this.state.selectedPaidOption === 'milestone') {
      if (this.validateForm()) {
        this.handleSubmitProposalsAPI();
      }
    } else if (this.validateProjectForm()) {
      this.handleSubmitProposalsAPI();
    }
  }; 
  
  
  handleSubmitProposalsAPI = () => {
    const submitProposals = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    const header = {
      "token": this.state.authToken
    };
  
    const formData = new FormData();
    if(this.state.selectedPaidOption === 'milestone'){
      formData.append('[proposal_generations][payment_type]', 'milestone');
      formData.append('[proposal_generations][post_id]', this.state.jobId);
      formData.append('[proposal_generations][duration]', this.state.months);
      formData.append('[proposal_generations][total_price_of_project]', this.state.projectBudget);
      formData.append('[proposal_generations][you_will_receive]', this.state.youReceive);
      formData.append('[proposal_generations][freelancer_service_fee]', this.state.savioServiceFee);
      formData.append('[proposal_generations][cover_letter]', this.state.text);
      formData.append(`[proposal_generations][resume_document]`, this.state.files?.[0]); 
      this.state.milestones.forEach((milestone, index) => {
        formData.append(
          `[proposal_generations][milestones_attributes][${index}][description]`,
          milestone.description
        );
        formData.append(
          `[proposal_generations][milestones_attributes][${index}][date]`,
          milestone.date
        );
        formData.append(
          `[proposal_generations][milestones_attributes][${index}][amount]`,
          milestone.amount
        );
      });
      
    }else{
      formData.append('[proposal_generations][proposal_type]', 'project');
      formData.append('[proposal_generations][post_id]', this.state.jobId);
      formData.append('[proposal_generations][duration]', this.state.months);
      formData.append('[proposal_generations][status]', 'active');
      formData.append('[proposal_generations][total_price_of_project]', this.state.projectBudget);
      formData.append('[proposal_generations][you_will_receive]', this.state.youReceive);
      formData.append('[proposal_generations][freelancer_service_fee]', this.state.savioServiceFee);
      formData.append('[proposal_generations][cover_letter]', this.state.text);
      formData.append(`[proposal_generations][resume_document]`, this.state.files?.[0]); 
      formData.append('[proposal_generations][submission_date]', '2023-10-30');
    }
  
    this.submitProposalsId = submitProposals.messageId;
    submitProposals.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      '/bx_block_proposal_generation/proposal_generations'
    );
    submitProposals.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    submitProposals.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData // Add FormData directly
    );
    submitProposals.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.addMilestoneMethodeType
    );
    runEngine.sendMessage(submitProposals.id, submitProposals);
  };

  handleJobDetailsRes = (requestCallId: any,message:any) => {
    if (this.fetchCurrentProposalsJobDetailsId === requestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      ;
      

      if (responseJson) {
          this.setState({ jobDetailsData: responseJson.data.attributes });
      } else {
        const errorMessage = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorMessage);
       
      }
  }
  }
  handleSubmissionDateChange = (e:any) => {
    const selectedDate = e.target.value;
    const submissionDateError = this.validateSubmission(selectedDate);
    this.setState({
      selectedDate,
      errors: {
        ...this.state.errors,
        submissionError: submissionDateError,
      },
    });
  }

  handleFileRemove =(index:number) => {
    this.setState((prevState) => {
      const updatedFiles = [...prevState.files];
      updatedFiles.splice(index, 1);
      return { files: updatedFiles };
    });
  };

  handleredirection = (componentName: any) => {
    this.props.navigation.navigate(componentName);
  };
 
    
  handleDateChange = (event:{ target: { value: string; }; }) => {
    const { value } = event.target;
    this.setState({ selectedDate: value });
  };

  handleFileChange = (event: any) => {
    const selectedFiles = Array.from(event.target.files); // Convert FileList to array
    const existingFiles = this.state.files || [];
    const totalFiles = [...existingFiles, ...selectedFiles];
  
    const errors: any[] = [];
    const validFiles: any[] = [];
  
    // Check if the total number of files exceeds 10
    if (totalFiles.length > 10) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          resume: "You can only upload up to 10 files.",
        },
      }));
      return;
    }
  
    // Validate files
    totalFiles.forEach((file) => {
      const error = this.validateResume(file); // Validate a single file
      if (error) {
        errors.push({ name: file.name, error });
      } else {
        validFiles.push(file);
      }
    });
  
    this.setState((prevState) => ({
      files: validFiles,
      errors: {
        ...prevState.errors,
        resume: errors.length
          ? errors.map((e: any) => `${e.name}: ${e.error}`).join(", ")
          : undefined,
      },
    }));
  };
  
  validateResume = (resume: any): string => {
    if (!resume) {
      return "*Please attach your work samples.";
    }
  
    const maxSize = 25 * 1024 * 1024; // 25MB
    if (resume.size > maxSize) {
      return "File size should not exceed 25MB.";
    }
  
    return ""; 
  };

  handleImageClick = () => {
    const fileInput = document.getElementById("file-upload-input") as HTMLInputElement;
    if (fileInput) {
      fileInput.click();
    }
  };


  isFormValid() {
    const { projectBudget, months, uploadedResume } = this.state;
    // Check if all required fields are filled
    return projectBudget.trim() !== '' && months.trim() !== '' && uploadedResume !== "";
}

    
  // Customizable Area End

  // Customizable Area Start
  async receive(_form: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      if (this.submitProposalsId === requestCallId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        ;
        

        if (responseJson) {
        
          if(responseJson.message){
            this.setState({isDialogOpen: true})

          }        
          
        } else {
          const errorMessage = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage);
         
        }
    }
    
    this.handleJobDetailsRes(requestCallId, message)
  }
    // Customizable Area End
  }
  // Customizable Area End
}
