import { IBlock } from '../../../framework/src/IBlock';
import { Message } from '../../../framework/src/Message';
import { BlockComponent } from '../../../framework/src/BlockComponent';
import MessageEnum, {
  getName
} from '../../../framework/src/Messages/MessageEnum';
import { runEngine } from '../../../framework/src/RunEngine';

// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";

// Customizable Area End

export const configJSON = require('./config');

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  months: string;
  open: any;
  reason: any;
  savioServiceFee: any;
  projectBudget: any;
  selectedDate: any;
  youReceive: any;
  authToken:any;
  message:any;
  proposalId:any;
  uploadedResume:any;
  text: string;
  describeYourExp: any;
  jobId: any;
  submitProposalRes:object;
  files: any;
  jobDetailsData:any;
  milestones:any [],
  errors: any;
  selectedPaidOption: string,
  isDialogOpen: boolean,
  showFullContent: boolean,
  archivedList: any
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class EditSubmitProposalController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  withdrawProposalsId:any;
  submitProposalsId:any;
  fetchCurrentProposalsJobDetailsId:any
  maxLength: any;
  getProposalDetailsId: any
  editProposalDetailsId: any
  getArchiveProposalDetailsId: any

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];

    this.withdrawProposalsId="";
    this.submitProposalsId="";
    this.fetchCurrentProposalsJobDetailsId="";
    this.getArchiveProposalDetailsId="";
    this.getProposalDetailsId="";
    this.editProposalDetailsId=""
    this.maxLength=400
    this.state = {
        projectBudget: '',
        showFullContent: false,
        open: false,
        reason: '',
        savioServiceFee: '5',
        selectedDate: '',
        youReceive: '',
        uploadedResume:"",
        submitProposalRes:{},
        jobId:"",
        months: '',
        jobDetailsData:{},
        proposalId:"24",
        text: '',
        describeYourExp:"",
        files: [],
      authToken: '',
      message:"",
      selectedPaidOption: 'milestone',
      milestones: [
        { id: 1, description: '', date: '', amount: '', },
      ],
      isDialogOpen: false,
      archivedList: [],
      errors: {},
    };
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area Start

    this.setState({
      authToken: await storage.get("authToken"),
    })
    this.setState({jobId:await storage.get('currentProposalJobId')});
    this.fetchCurrentProposalsJobDetails()
    this.getArchiveProposalsDetails()

    // Customizable Area End
  }

  // Customizable Area Start

  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false });
    this.props.navigation.navigate("FullWidthTabProposals")
  };

  
  handleJobPost = () => {
    this.props.navigation.navigate('SearchJob')
  }

  getArchiveProposalsDetails=()=>{
    const paramId = this.props.navigation.getParam("id")
    const currentProposalsDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    ); 
    const header = {
      "token": this.state.authToken, 
      'Content-Type': configJSON.dashboarContentType,
    };
    this.getArchiveProposalDetailsId = currentProposalsDetails.messageId;

    currentProposalsDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_proposal_generation/proposal_generations/proposal_view_archived_detail?proposal_id=${paramId}`
    );

    currentProposalsDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    currentProposalsDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(currentProposalsDetails.id, currentProposalsDetails);
  };

  handleMonths = (event: any) => {
    this.setState({ months: event.target.value });
    
  };
  viewProposals=()=>{
    this.props.navigation.navigate("FullWidthTabProposals")
    this.setState({ 
      
      
      isDialogOpen: false });
  }

  toggleContent = () => {
    this.setState(prevState => ({
      showFullContent: !prevState.showFullContent,
    }));
  };
  
  handleHome = () => {
    this.setState({ isDialogOpen: false });
    this.props.navigation.navigate("SearchJob")
  }
  handleClickOpen = () => {
    this.props.navigation.navigate('SendProposals')
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  validateSubmission(date: string) {
    if (!date.trim()) {
      return "*Please enter a submission date";
    }
  
    const selectedDate = new Date(date);
    const today = new Date();
  
    today.setHours(0, 0, 0, 0);
  
    if (selectedDate < today) {
      return "*Submission date cannot be in the past";
    }
  
    return "";
  }  
  
  handleChange =(event:any )=>{

    const { name, value } = event.target;

    this.setState(({

      [name]: value

    } as unknown) as Pick<S, keyof S>);
  };

  
  handleProjectBudget=(event: any) => {
    const { value } = event.target;
    const numericRegex = /^\d+(?:\.\d*)?$/
    if (numericRegex.test(value) || value === '') {

      const numericValue=parseFloat(value);
      const commission=isNaN(numericValue) ? 0 : numericValue * 0.05;
      const remainingAmount=isNaN(numericValue) ? '' : (numericValue - commission).toFixed(2);
      this.setState({
        projectBudget: value,
        youReceive: remainingAmount,
        errors: {
          ...this.state.errors,
          bid: '', 
        },
      })
    }
     else { this.setState({
      errors:{
          ...this.state.errors ,
          bid:'*Please enter a bid amount' ,
        }});
    }
  }; 
  
  handleWithdrawProposals=() => {
    const withdrawProposals=new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    )
    const header = {
      "Content-Type": configJSON.apiContentType,
    }
    const payload = {
      proposals: {
        reason: this.state.reason,
        message: this.state.message
      },
      token: this.state.authToken
    }
    this.withdrawProposalsId =withdrawProposals.messageId
    withdrawProposals.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.withdrawProposalsEndpoint}/${this.state.proposalId}/withdraw_proposal`
    )
    withdrawProposals.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
       JSON.stringify(header)
    )
    withdrawProposals.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(payload)
    )
    withdrawProposals.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutMethod
    )
    runEngine.sendMessage(withdrawProposals.id, withdrawProposals)
  }
  
   handlePaidOptionChange = (event: { target: { value: string; }; }) => {
  this.setState({ selectedPaidOption: event.target.value });
      };

  handleSubmitProposalRes = (requestCallId:any,message:Message) => {
    if (this.getProposalDetailsId === requestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
  

      if (responseJson) {
          this.setState({ jobDetailsData: responseJson?.data?.attributes?.archived_proposal?.data?.attributes });
      } else {
        const errorMessage = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorMessage);
       
      }
  }
}

  validateCoverLetter(coverLetter:string) {
    if (!coverLetter.trim()) {
      return "*Please enter a cover letter";
    }
    if (coverLetter.length < 20) {
      return "Cover letter must be at least 20 characters";
    }
    return "";
  }
  
  validateBid(bid: string) {
    if (!bid.trim()) {
      return "*Please enter a bid amount";
    }
    if (isNaN(Number(bid)) || Number(bid) <= 0) {
      return "Enter a valid bid amount";
    }
    return "";
  }

  handleDateChange=(event:{target:{value:string} })=>{
    const {value} = event.target
    this.setState({ selectedDate: value });
  };

  handleSubmissionDateChange=(e:any)=>{
    const selectedDate = e.target.value

    const submissionDateError = this.validateSubmission(selectedDate)
    this.setState({
      selectedDate,
      errors: {
        ...this.state.errors,
        submissionError: submissionDateError
      }
    })
  }
  
  handleSubmit = () => {
    this.handleSubmitProposalsAPI();
  }
 
  
  handleSubmitProposalsAPI = () => {
    const submitProposals = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    const header = {
      "token": this.state.authToken
    };
    const paramId = this.props.navigation.getParam("id")

    const formData = new FormData();
      formData.append('[proposal_generations][payment_type]', 'project');
      formData.append('[proposal_generations][post_id]', this.state.jobId);
      formData.append('[proposal_generations][duration]', this.state.months);
      formData.append('[proposal_generations][status]', 'active');
      formData.append('[proposal_generations][total_price_of_project]', this.state.projectBudget);
      formData.append('[proposal_generations][you_will_receive]', this.state.youReceive);
      formData.append('[proposal_generations][freelancer_service_fee]', this.state.savioServiceFee);
      formData.append('[proposal_generations][cover_letter]', this.state.text);
      formData.append(`[proposal_generations][resume_document]`, this.state.files?.[0])
  
    this.submitProposalsId = submitProposals.messageId;
    submitProposals.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_proposal_generation/proposal_generations/${paramId}/update_proposal`
    );
    submitProposals.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    submitProposals.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    submitProposals.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'PATCH'
    );
    runEngine.sendMessage(submitProposals.id, submitProposals);
  };
  
  fetchCurrentProposalsJobDetails=()=>{
    const proposalsJobDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    ); 
    const header = {
      "token": this.state.authToken, 
      'Content-Type': configJSON.dashboarContentType,
    };
    this.fetchCurrentProposalsJobDetailsId = proposalsJobDetails.messageId;

    proposalsJobDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.fetchCurrentProposalsJobDetailsEndPoint+`${this.state.jobId}`
    );

    proposalsJobDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    proposalsJobDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(proposalsJobDetails.id, proposalsJobDetails);
  };

  handleJobDetailsRes=(requestCallId: any,message:any)=>{
    if (this.fetchCurrentProposalsJobDetailsId === requestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
          this.setState({ jobDetailsData: responseJson.data.attributes });
      } 
      else {
        const errorMessage = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        )
        this.parseApiCatchErrorResponse(errorMessage)
      }
  }
  }

  handleArchiveJobDetailsRes = (requestCallId: any,message:any) => {
    if (this.getArchiveProposalDetailsId === requestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      ;
  

      if (responseJson) {
          this.setState({ archivedList: responseJson });
          this.responseState(responseJson)
      } else {
        const errorMessage = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorMessage);
       
      }
  }
  }

  responseState = (responseJson:any) => {
    const {total_price_of_project, cover_letter, resume_document,you_will_receive } = responseJson?.proposal?.data?.attributes?.proposal_attributes
    const {project_length} = responseJson?.proposal?.data?.attributes?.post_attributes?.data?.attributes
    this.setState({
        projectBudget : total_price_of_project,
        text: cover_letter,
        months: project_length,
        files:resume_document,
        youReceive: you_will_receive
    })
  }
  
  handleredirection = (componentName: any) => {
    this.props.navigation.navigate(componentName);
  };

  handleFileRemove = (index: number) => {
    this.setState((prevState) => {
      const updatedFiles = [...prevState.files];
      updatedFiles.splice(index, 1);
      return { files: updatedFiles };
    });
  };

  handleTextChange=(e: any)=>{
    const text = e.target.value
    const coverLetterError = this.validateCoverLetter(text)
    this.setState({
      text, errors: {...this.state.errors, coverLetter: coverLetterError },
    });
  };
  

  handleFileChange=(event: any)=>{
    const selectedFiles=Array.from(event.target.files)
    const existingFiles=this.state.files || []
    const totalFiles=[...existingFiles, ...selectedFiles]

    if (totalFiles.length>5) {
      this.setState((prevState)=>({
        errors: {
          ...prevState.errors, resume: "You can only upload up to 5 files.",
        }}))
      return
    }
    const validFiles: any[] = []
    const errors: string[] = []
    totalFiles.forEach((file)=>{
      const error = this.validateResume([file]);
      if (error) {
        errors.push(`${file.name}: ${error}`);
      } else {
        validFiles.push(file);
      }
    });
  
    this.setState((prevState) => ({
      files: validFiles,
      errors: {
        ...prevState.errors,
        resume: errors.length ? errors.join(", ") : undefined,
      },
    }));
  };
  
  validateResume = (resume: any): string => {
    console.log(resume, "resume");
  
    if (!Array.isArray(resume) || resume.length === 0) {
      return "*Please attach at least one CV.";
    }
  
    const maxSize = 5 * 1024 * 1024;
  
    for (const file of resume) {
      if (file.size > maxSize) {
        return `${file.name}: File size should not exceed 5MB.`;
      }
    }
  
    return ""
  }
handleImageClick= ()=> {
    const fileInput=document.getElementById("file-upload-input") as HTMLInputElement
    if (fileInput){
      fileInput.click()
    };}
    
  // Customizable Area End

  // Customizable Area Start
       async receive(_form: string,message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage)===message.id) {
      const requestCallId=message.getData(
         getName(MessageEnum.RestAPIResponceDataMessage)
      )
      if(this.submitProposalsId===requestCallId){
        const responseJson=message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        )
        if(responseJson)  {
          if (responseJson.message){
            this.setState({
              isDialogOpen: true
            })
          }        
        } else{
        const errorMessage=message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
          );
          this.parseApiCatchErrorResponse(errorMessage)}}
          this.handleJobDetailsRes(requestCallId, message)
          this.handleArchiveJobDetailsRes(requestCallId, message)
    this.handleSubmitProposalRes(requestCallId, message)

    
  }
    // Customizable Area End
  }

  editProposalsDetails=()=>{
    const paramId = this.props.navigation.getParam("id")
    const currentProposalsDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    ); 
    const body = {

    }
    const header = {
      "token": this.state.authToken, 
      'Content-Type': configJSON.dashboarContentType,
    };
    this.editProposalDetailsId = currentProposalsDetails.messageId;

    currentProposalsDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_proposal_generation/proposal_generations/${paramId}/update_proposal`
    );

    currentProposalsDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    currentProposalsDetails.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      );

    currentProposalsDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
     'POST'
    );
    runEngine.sendMessage(currentProposalsDetails.id, currentProposalsDetails);
  };

  getProposalsDetails=()=>{
    const paramId = this.props.navigation.getParam("id")
    const currentProposalsDetails = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    ); 
    const header = {
      "token": this.state.authToken, 
      'Content-Type': configJSON.dashboarContentType,
    };
    this.getProposalDetailsId = currentProposalsDetails.messageId;

    currentProposalsDetails.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/my_proposals/view_by_id_archived_proposal?id=${paramId}`
    );

    currentProposalsDetails.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    currentProposalsDetails.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(currentProposalsDetails.id, currentProposalsDetails);
  };
  // Customizable Area End
}
