import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import storage from "../../../framework/src/StorageProvider";
// Customizable Area End

export const configJSON = require("./config.js");

export interface Props {
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}
interface S {
    // Customizable Area Start
    openBillingModal: boolean
    openPaymentCardModal:boolean
    selectedValue: string,
    showPayPalSection: boolean
    showAddPaymentCard: boolean
    isDialogOpen: boolean
    userDetails: any
    successMessage: string
    errorMessage: string
    authToken:any;
    paymentSuccessPopup: boolean
    showDetails: boolean
    // Customizable Area End
}
interface SS { }

export default class ClientBillingInformationController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    paypalApiCallDetailsId: any
    redirectPaypalApiCallDetailsId: any
    userCallID: any
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        console.disableYellowBox = true;
        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
        ];
        this.paypalApiCallDetailsId = "",
        this.redirectPaypalApiCallDetailsId = "",
        this.userCallID = "",
        this.state = {
            openBillingModal: false,
            openPaymentCardModal:false,
            selectedValue: 'PaymentCard',
            showPayPalSection: false,
            showAddPaymentCard:false,
            isDialogOpen: false,
            userDetails: [],
            successMessage: '',
            errorMessage: '',
            authToken: '',
            paymentSuccessPopup: false,
            showDetails: false
        };
        // Customizable Area End
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getDashboardData();
        // Customizable Area Start
        this.setState({
            authToken: await storage.get("authToken"),
          })
          this.getUserData()
        // Customizable Area End
    }

    getDashboardData(): boolean {
        // Customizable Area Start

        // Customizable Area End
        return true;
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const webApiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
        
            let webResponseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
        
        if (webResponseJson) {
            if (webApiRequestCallId === this.paypalApiCallDetailsId) {
            console.log(webResponseJson,"webResponseJson")
            console.log(webResponseJson?.error,"webResponseJson?.message")
            if (webResponseJson?.error) {
                this.setState({
                    isDialogOpen: true,
                    errorMessage: webResponseJson?.error
                })
                }
            if (webResponseJson?.message) {
            this.setState({
                isDialogOpen: true,
                successMessage: 'Succesfully Created'
            })
            }
            console.log(webResponseJson,"webResponseJson")
            this.setState({isDialogOpen: true})
            console.log(webResponseJson,"")
            } 
        }

        if (webResponseJson) {
            if (webApiRequestCallId === this.userCallID) {
                this.setState({
                    userDetails: webResponseJson?.current_user
                })
            console.log(webResponseJson?.current_user,"webResponseJson")
            } 
        }

          }
        // Customizable Area End
    }
    // Customizable Area Start 
    handleCloseBillingModal =()=>{
        this.setState({ openBillingModal: false });
    }
    handleNextButton = () => {
        if (this.state.selectedValue === 'PayPal') {
            this.paypalApiCall()
            this.setState({ showPayPalSection: true });
        } else if (this.state.selectedValue === 'PaymentCard') {
            this.setState({ openPaymentCardModal: true });
        }
        this.handleCloseBillingModal();
    };
    handleBillingMethod = ()=>{
        this.setState({ openBillingModal: true });
    }
    handlePaymentChange = (event: { target: { value: any; }; }) => {
        const { value } = event.target;
        this.setState({
            selectedValue: value,
            showPayPalSection: value === 'PayPal' || this.state.showPayPalSection,
            showAddPaymentCard: value === 'PaymentCard' || this.state.showAddPaymentCard,
        });
    };
    
    handleClosePaymentCardModal = ()=>{
        this.setState({ openPaymentCardModal: false });
    }
    handlePaymentCardSaveBtn = ()=>{
        this.setState({ openPaymentCardModal: false,showAddPaymentCard:true });
    }

      paypalApiCall = () => {
        const paramId = this.props.navigation.getParam("id");
        const currentProposalsDetails = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      
        const header = {
          token: this.state.authToken,
          "Content-Type": "application/json", 
        };
      
        const body = {
          payment_type: "paypal",
        };
      
        this.paypalApiCallDetailsId = currentProposalsDetails.messageId;
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_stripe_integration/add_paypal_payment_method`
        );
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body) // Ensure body is properly formatted
        );
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
      
        runEngine.sendMessage(currentProposalsDetails.id, currentProposalsDetails);
      };
      
      redirectPaypalApiCall = () => {
        const currentProposalsDetails = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
          token: this.state.authToken,
          "Content-Type": "application/json", 
        };
        const body = {
          payment_type: "paypal",
        };
      
        this.redirectPaypalApiCallDetailsId = currentProposalsDetails.messageId;
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          `bx_block_stripe_integration/paypal_client_to_admin?milestone_id=168`
        );
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          JSON.stringify(body) 
        );
      
        currentProposalsDetails.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          "POST"
        );
        runEngine.sendMessage(currentProposalsDetails.id, currentProposalsDetails);
      };

      getUserData = async () => {

          
          const requestMessage = new Message(
              getName(MessageEnum.RestAPIRequestMessage)
            );
            
            const header = {
                "Content-Type": configJSON.dashboarContentType,
                token: this.state.authToken
            };
            this.userCallID = requestMessage.messageId;
   
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          'account_block/accounts/get_client_profile_online_status'
        );
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
         "GET"
        );
      
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

    handleOkay = () => {
        this.redirectPaypalApiCall()
        this.setState({ 
            isDialogOpen: false,
            paymentSuccessPopup: true,
         });
    }

    handleCloseDialog = () => {
        this.setState({ isDialogOpen: false });
      };
    handleCloseSuccessPopup = () =>{
        this.setState({ paymentSuccessPopup: false, showDetails: true})
    }

    handleDeleteMethod = (method: string) => {
        if (method === "PayPal") {
          this.setState({ showPayPalSection: false });
        } else if (method === "PaymentCard") {
          this.setState({ showAddPaymentCard: false });
        }
      };
        // Customizable Area End
}