import React from 'react';

// Customizable Area Start
import {
  Typography,
  Grid,
  withStyles,
  Box,
  FormControl,
  Button,
  Container,
  Divider,
  OutlinedInput,
  InputAdornment,
  Select,
  TextField,
  Radio,
  Checkbox,
  Dialog,
  DialogContent,
  FormControlLabel,
  RadioGroup,
  IconButton,
  Paper,
  styled
} from '@material-ui/core';
import ViewSubmitProposalController, {
  Props
} from './ViewSubmitProposalController';
export const configJSON = require('./config');
import FreelancerNavBar from './FreelancerNavBar.web';
import FooterWeb from './Components/Footer.web';
import CloseIcon from "@material-ui/icons/Close";
import { experienceicon, pdfImage, priceLatestTag } from './Components/assets';

// Customizable Area End

// Customizable Area Start

// Customizable Area End

export class ViewSubmitProposal extends ViewSubmitProposalController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start

  renderSuccessModal = () =>{
    return(
      <>
         <CustomDialogBox
      open={this.state.isDialogOpen}
      onClose={this.handleCloseDialog}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
  >
       
      <DialogContent style={{
         padding:"60px" }}>
          <Typography gutterBottom style={webStyle.congratsText}>
          {this.state.responseMessage === 'Proposal already withdrawn' ? 
            ''
            :
            'Congratulations! '
            }
               
              </Typography>
          <Typography style={webStyle.profileCreatedText}>
            {this.state.responseMessage === 'Proposal already withdrawn' ? 
            this.state.responseMessage
            :
            'You have successfully withdrawn the proposal.'
            }
          </Typography>
          <Box style={{ display: "flex",
           flexDirection: "column" }}>
              <Button
                  variant="outlined"
                  style={webStyle.blueButton}
                  onClick={this.handleHome}
              >
                 OK
              </Button>
          </Box>
      </DialogContent>
  </CustomDialogBox>
      </>
    )
  }
  // Customizable Area End
  render() {
    // Customizable Area Start
const jobDetailsDatas = this.state.jobDetailsData?.post?.data?.attributes || {}
const archivedProposals = this.state.archivedList?.proposal?.data?.attributes || {}
const archivePostData = archivedProposals?.post_attributes?.data?.attributes
const mappingData = this.state.archivedList?.proposal ? archivePostData : jobDetailsDatas
const mappingID = this.state.archivedList?.proposal ? this.state.archivedList?.proposal?.data?.id : this.state.jobDetailsData?.id
    const { classes } = this.props;
    // Customizable Area End
    return (
      // Customizable Area Start

      <>
        <FreelancerNavBar navigation={this.props.navigation} />
        <Container style={{ marginTop: '2rem' }}>
        <Box>
          <Typography
                style={{
                  marginTop: '72px',
                  fontSize: '36px',
                  fontWeight: 700,
                  fontFamily: 'Rubik',
                  color:'#4A4A4A',
                  // marginLeft: '100px',
                  marginBottom: '33px',
                }}
              >
              Job Proposal
              </Typography>
          </Box>
          <Box component={'div'} className={classes.boxStyle}>
            <Box className={classes.mainCenterBox}> 
            <Box className={classes.boxRight}>
            <Typography style={{fontSize:'24px',color: '#303030',  fontFamily: 'Rubik', marginTop: '0px'}} className={classes.headerBold}>Job Details</Typography>
            <Typography className={classes.text}>
            {mappingData?.name}
           </Typography>
              <Box style={{display: 'flex', gap:'12px'}}>
                <Typography style={{backgroundColor: '#E6EBEF',
                 fontSize: '16px',
                }} className={classes.php}>
                    {mappingData?.job_title}
                </Typography>
                <Typography className={classes.postTime}>
                {mappingData?.created_at}
                  </Typography>
                  </Box> 
                   <Typography className={classes.projectDesc}>
                   {mappingData?.description}
                  </Typography>  
                  <Typography className={classes.php} 
                  style={{background:'none',padding:0,textDecoration: 'underline',fontSize:'16px'}}>View job posting</Typography>   
            </Box>

            <Box className={classes.boxLeft}>
            <Box className={classes.experienceBox}>
                    <Box display={'flex'} style={{gap:'10px'}}>
                  <img src={experienceicon} alt="img" width={25} height={25}/> 
                  <Typography style={{marginTop:'0px',color:'#364BA0'}} className={classes.text}>
                  {mappingData?.experience_level}
                  </Typography>
                  </Box>
                  <Typography className={classes.text2}>
                   Experience Level
                  </Typography>
                  </Box>

                  <Box className={classes.experienceBox}>
                    <Box display={'flex'} style={{gap:'10px'}}>
                  <img src={priceLatestTag} alt="img" width={25} height={25}/> 
                  <Typography style={{marginTop:'0px',color:'#364BA0'}} className={classes.text}>
                  ${mappingData?.per_day_charge}
                  </Typography>
                  </Box>
                  <Typography className={classes.text2} style={{marginTop: '0px',marginLeft: '35px'}}>
                   Project Budget
                  </Typography>
                  </Box>
            </Box>
            </Box>
          <Divider className={classes.dividerStyle} style={{margin: '32px 0px 32px'}}/>
          <Typography style={{fontSize:'24px'}} className={classes.text}>
                  Skills and expertise:
                </Typography>
                <Box style={{ display: 'flex' }}>
                {mappingData?.skills?.map((skill: any, index: number) => {
                    return (
                      <Typography  style={{marginTop: '18px'}} className={classes.php} key={index}>
                        {skill}
                      </Typography>
                    );
                  })}
                </Box>
          </Box>
          {this.state.archivedList?.proposal &&
           <Box component={'div'} style={{padding:'0px'}} className={classes.boxStyle}>
           <Typography style={{fontSize:'24px',margin:'32px 32px 0px 32px'}} className={classes.text}>Your proposed terms</Typography>
           <Divider style={{color: '#858585',margin: '24.5px 0px 32.5px'}} className={classes.dividerStyle} />
       <Box style={{padding:'0px 32px 32px'}}>
           <Typography className={classes.text}>
           How do you want to be paid?
           </Typography>
         <Box style={{marginTop:'24px'}}>
         <Grid container spacing={2} className={classes.gridPosition}>
             <Grid item md={8} xs={12}>
               <Typography className={classes.headerBold}>
              {archivedProposals?.payment_type}
               </Typography>
               <p style={{fontSize: '16px'}} className={classes.subHeading}>
               Get your entire payment at the end, when all work has been delivered.
               </p>
             </Grid>
           </Grid>

           <Grid container spacing={2} className={classes.gridPosition}>
             <Grid item md={8} xs={12}>
               <Typography className={classes.headerBold}>
               Bid:
               </Typography>
               <p className={classes.subHeading}>
               Total amount the client will see on your proposal
               </p>
             </Grid>
             <Grid item md={4} xs={12}>
               <OutlinedInput
                 fullWidth
                 value={archivedProposals?.proposal_attributes?.total_price_of_project}
                 data-testid="project-btn"
                 name="projectBudget"
                 disabled
                 onChange={this.handleProjectBudget}
                 inputProps={{
                   style: {
                     textAlign: 'end',
                     color: '#363636',
                     fontSize: '16px',
                     fontFamily: 'Rubik',
                     fontWeight: 700
                   }
                 }}
                 startAdornment={
                   <InputAdornment
                     position="start"
                  
                   >
                     $
                   </InputAdornment>
                 }
                 endAdornment={
                   <InputAdornment
                     position="end"
                     className={classes.endtext}
                   >
                     /project
                   </InputAdornment>
                 }/>
             </Grid>
           </Grid>
           <Grid container spacing={2} className={classes.gridPosition}>
             <Grid item md={8} xs={12}>
               <Typography className={classes.headerBold}>
               Inventohub Service Fee:
               </Typography>
               <p className={classes.subHeading}>
               Inventohub will charge a small service fee from the total amount
               </p>
             </Grid>
             <Grid item md={4} xs={12}>
               <OutlinedInput
                 fullWidth
                 value={archivedProposals?.proposal_attributes?.freelancer_service_fee}
                 disabled
                 name="savioServiceFee"
                 startAdornment={
                   <InputAdornment position="start">%</InputAdornment>
                 }
                 inputProps={{
                   style: {
                     textAlign: 'end'
                   }
                 }}
               />
             </Grid>
           </Grid>
           <Grid container spacing={2} className={classes.gridPosition}>
             <Grid item md={8} xs={12}>
               <Typography className={classes.headerBold}>
               You'll Receive:
               </Typography>
               <p className={classes.subHeading}>
               The estimated amount you'll receive after service fees
               </p>
               <p style={{fontSize:'12px',marginBottom:'6px'}} className={classes.subHeading}>
               *Payment gateway and other fees will be deducted from your earnings
               </p>
             </Grid>
             <Grid item md={4} xs={12}>
               <OutlinedInput
                 fullWidth
                 value={archivedProposals?.proposal_attributes?.you_will_receive}
                 disabled
                 name="youReceive"
                 inputProps={{
                   style: {
                     textAlign: 'end',
                     color: '#363636',
                     fontSize: '16px',
                     fontFamily: 'Rubik',
                     fontWeight: 700
                   }
                 }}
                 startAdornment={
                   <InputAdornment position="start">$</InputAdornment>
                 }
               />
             </Grid>
           </Grid>
         </Box>
         <Typography className={classes.flagStyle}>We do not deduct any tax. You need to pay taxes according to your country.</Typography>
         </Box>
         </Box>}
         
          <Box component={'div'} className={classes.boxStyle}>
            <Typography style={{marginTop:'0px'}} className={classes.headerBold}>
              Project Duration
            </Typography>
            <FormControl variant="outlined" className={classes.customFormControl}>
              <Select
              className={classes.customSelect}
                native
                value={mappingData?.project_length}
                id="selectmenu"
                disabled
              >
                <option value="">
                 {mappingData?.project_length}
                </option>
              </Select>
            </FormControl>
          </Box>
          {this.state.archivedList?.proposal && 
          <Box component={'div'} style={{padding:'0px'}} className={classes.boxStyle}>
          <Box style={{padding:'32px'}}>
          <Typography style={{fontSize:'19px',marginBottom:'24px'}} className={classes.text}>
          Cover Letter
           </Typography>
           <Box
        sx={{
          position: "relative",
          width: "100%",
          maxWidth: 'auto',
        }}
      >
        <TextField
          multiline
          rows={10}
          variant="outlined"
          fullWidth
          inputProps={{ maxLength: this.maxLength }}
          className={classes.covertextfield}
          value={archivedProposals?.proposal_attributes?.cover_letter}
          disabled
        />
      </Box>
      <Typography style={{color: '#303030',marginBottom: '24px',marginTop: '24px'}} className={classes.headerBold}>
              Attachments
            </Typography>

        <Box display={'flex'} style={{gap:'20px'}}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                border: "1px solid #F5EFEF",
                borderRadius: "16px",
                padding: "8px 16px",
                marginBottom: "8px",
                backgroundColor: "#F9F9F9",
                width:'185px',
                height:'170px',
                textAlign: 'center'
              }}
            >
              <Box style={{margin: 'auto'}}>
                 <img
            src={pdfImage}
            alt="pdf"
            style={{
              width: "29px", 
              height: "29px",
              display:'flex',
              margin:'auto',
              left:23
            }}
          />
              <Typography variant="body2" 
              style={{width: '130px',
                color: '#535353',
                fontSize: '14px',
                fontFamily: 'Rubik'
              }}>{mappingData?.file_name}</Typography>
              </Box>
            </Box>
        </Box>
            </Box>
          </Box>
          }
          <Box component={'div'} sx={{ marginTop: '20px' }}>
            <Grid container spacing={2}>
             
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  data-testid="proposal-cancel-btn"
                  className={classes.btnStyle2}
                  onClick={() => this.handleClickOpen()}
                >
                  WITHDRAW PROPOSALS
                </Button>
              
              </Grid>
              <Grid item>
                <Button
                // disabled={!this.isFormValid()}
                  variant="contained"
                  data-testid="proposalSubmit-btn"
                  color="primary"
                  className={classes.btnStyle}
                  onClick={() => 
                    this.props.navigation.navigate('EditSubmitProposal',{id:mappingID})
                   } 
                >
                  CHANGE TERMS
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Container>
        <Dialog
                  onClose={this.handleClose}
                  aria-labelledby="customized-dialog-title"
                  open={this.state.open}
                  maxWidth="sm"
                  PaperProps={{
                    style: {
                      borderRadius: '16px',
                      maxWidth: '914px',
                    }}}
                 
                >
                  <Box
                    display={'flex'}
                    justifyContent={'space-between'}
                    style={{
                      paddingLeft: '32px',
                      paddingTop: '20px',
                      paddingRight: '32px'
                    }}
                  >
                    <Typography style={webStyle.dialogtext}>
                      Withdraw Proposal
                    </Typography>

                    <IconButton
                      onClick={() => this.handleClose()}
                      data-testid="dailog-close-btn"
                      style={{padding: '0'}}
                    >
                      <CloseIcon
                        style={{ fontSize: '27px',color:'black' }}
                      />
                    </IconButton>
                  </Box>

                  <DialogContent style={{overflowX:"hidden", padding: '24px 32px'}}>
                    <Typography style={{
                        fontFamily: 'Rubik',
                        fontSize: '16px',
                        color: '#2B2B2B',
                        marginBottom: '24px'
                    }} >
                      We will politely notify the client that you are not
                      interested. The client will be able to view the reason
                      you've withdrawn your proposal.
                    </Typography>
                    <Typography
                      style={{
                        ...webStyle.dialogtext,
                        fontSize: '22px',
                        fontWeight: 600,
                      }}
                    >
                      Reason
                    </Typography>
                    <RadioGroup
                      style={webStyle.RadioGroupBox}
                      aria-label="reason"
                      name="reason"
                      value={this.state.reason}
                      onChange={this.handleReasonChange}
                      data-testid="reason"
                    >
                      <FormControlLabel
                        value="Applied by mistake"
                        control={<Radio style={{ color: '#364BA0' }}  />}
                        label="Applied by mistake"
                      />
                      <FormControlLabel
                        value="Rate too low"
                        control={<Radio style={{ color: '#364BA0' }} />}
                        label="Rate too low"
                      />
                      <FormControlLabel
                        value="Scheduling confilct with client"
                        control={<Radio style={{ color: '#364BA0' }} />}
                        label="Scheduling confilct with client"
                      />
                      <FormControlLabel
                        value="Unresponsive client"
                        control={<Radio style={{ color: '#364BA0' }} />}
                        label="Unresponsive client"
                      />
                      <FormControlLabel
                        value="Inappropriate client behavior"
                        control={<Radio style={{ color: '#364BA0' }} />}
                        label="Inappropriate client behavior"
                      />
                      <FormControlLabel
                        value="Other"
                        control={<Radio style={{ color: '#364BA0' }} />}
                        label="Other"
                      />
                    </RadioGroup>
                    {this.state.errors.reasonserror && (
                        <Typography
                        style={{
                            color: 'red',
                            marginTop: '8px',
                            fontSize: '14px',
                        }}
                        >
                        {this.state.errors.reasonserror}
                        </Typography>
                    )}
                                    <Typography
                      style={{
                        ...webStyle.dialogtext,
                        fontSize: '22px',
                        fontWeight: 600,
                        marginTop:'24px',
                        color:'#2B2B2B'
                      }}
                    >
                      Message
                    </Typography>
                    <Typography  style={{
                        ...webStyle.dialogtext,
                        fontSize: '16px',
                        fontWeight: 400,
                        marginTop:'12px',
                        marginBottom: '16px',
                      }}>
                      Add an optional message to share with the client when we
                      notify them that this proposal has been withdrawn.
                    </Typography>
                    <textarea
                      name="message"
                      value={this.state.message}
                      onChange={this.handleMessageChange}
                      style={{
                        borderColor: '#979797',
                        borderRadius: '10px',
                        fontFamily: 'Rubik',
                        paddingLeft: '20px',
                        paddingTop: '20px',
                        height: '130px',
                        width: '100%'
                      }}
                      
                    />
                          {this.state.errors.coverLetter && (
                            <Typography style={{ color: 'red',
                                marginBottom: '24px',
                                fontSize:'14px'
                            }}>
                                {this.state.errors.coverLetter}
                            </Typography>
                            )}
                    <Box display={'flex'} alignItems={'center'} style={{gap: '5px',marginTop: '16px'}}>
                      <CustomCheckbox
                      checked={this.state.isBlocked}
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        onChange={this.handleCheckboxChange}
                      />
                      <Typography>
                        Block future invitations from this client
                      </Typography>
                    </Box>
                    <Box style={{marginTop: '58px',
                        display: 'flex',
                        justifyContent: 'end'
                    }}>
                    <Button
                      data-testid="dailog-cancel-btn"
                      onClick={() => this.handleClose()}
                      style={{
                        backgroundColor: '#E9F2F9',
                        color: '#364BA0',
                        marginRight: '12px',
                        padding: '10px 80px',
                        borderRadius: '10px',
                        fontSize: '20px',
                        fontFamily: 'Rubik'
                      }}
                    >
                      Cancel
                    </Button>
                    <Button
                      variant="contained"
                      style={{ backgroundColor: '#364BA0' ,
                        padding: '10px 30px',
                        borderRadius: '10px',
                        fontSize: '20px',
                        fontFamily: 'Rubik'
                      }}
                      color="primary"
                      data-testid="dailog-withdraw-proposal-btn"
                      onClick={this.handleSubmit}
                    >
                      withdraw proposals
                    </Button>
                    </Box>
                  </DialogContent>
                </Dialog>
        <FooterWeb />
        {this.state.isDialogOpen && this.renderSuccessModal()}
      </>
      // Customizable Area End
    );
  }
}

// Customizable Area Start

const webStyle = {
  placeholderText: {
    '&::placeholder': {
      color: '#000000',
    }
  },
  congratsText:
  {
    marginTop:"10px",
    fontWeight: 600,
    fontFamily: "Rubik",
    fontSize: "24px",
  color: "#515354",
  },
  profileCreatedText:{
   marginBottom: '20px',
   marginTop: "18px",
   fontFamily: "Rubik",
    fontSize: "24px",
    fontWeight: 500,
    color:'#515354'
},
profileCreatedText_remind:{
  fontWeight: 500,
  fontSize: "14px",
  fontFamily: "Rubik",
  marginBottom: '-7px',
  marginTop: "18px",
},
    textField: {
      borderRadius:"12px",
      width: "375px",
      marginTop:'12px',
      "&.MuiFormLabel-root.Mui-focused ": {
        color: "#6F6E6E !important",
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline ": {
        borderColor: "#6F6E6E !important",
        borderRadius: "12px !important"
      },
      "& .MuiOutlinedInput-root": {
        borderRadius: "12px !important"
      }
    },
  boxStyle: {
    border: '1px solid #CDCDCD',
    marginBottom: '32px',
    borderRadius: '16px',
    padding: '32px'
  },
  flagStyle: {
    marginTop: "24px",
    background: "#FEF3C7",
    color: "#D97706",
    padding: "10px",
    width: "443px",
    borderLeft: "4px solid #D97706",
    borderRadius: "4px",
    fontSize: "12px",
    fontFamily: "Rubik",
    fontWeight: 500,
    "@media(max-width:500px)": {
      width: "auto",
    },
  },
  box1Style: {
    border: '1px solid #e6e6e6',
    marginTop: '20px',
    borderRadius: '6px 6px 0 0',
    padding: '10px'
  },
  text:{
    fontSize: '20px',
    fontWeight: 600,
    fontFamily: 'Rubik',
    marginTop: '25px',
    color: '#303030'
  },
  headerBold: {
    fontWeight: 600,
    marginTop: '10px',
    fontFamily: 'Rubik',
    fontSize: '18px',
  },
  customFormControl:{
    fontFamily: 'Rubik'
  },
  customSelect: {
    fontFamily: 'Rubik',
    fontSize:'14px',
    color:'#4F4F4F',
    borderRadius: '8px',
    padding: '8px',
    height: '49px',
    width: '232px',
    marginTop: '16px',
  },
  subHeading: {
    color: '#595959',
    fontFamily: 'Rubik',
    fontSize: '14px',
    marginTop:'5px'
  },
  radioLabel:{
    color: 'black',
    fontFamily: 'Rubik',
    fontSize: '18px',
    "& .MuiTypography-root": {
      fontWeight: 700,
      fontFamily: 'Rubik',
      fontSize: '18px',
    },
  },
  gridPosition: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  milestoneMainBox:{
    display: 'flex', gap: '24px', width: '100%',marginTop:'40px',
    "@media(max-width:700px)": {
      display: 'block',
    },
  },
  milestoneBox: {
   width:'55%',
   "@media(max-width:700px)": {
    width: '100%',
    marginTop: '10px'
  },
  },
  dateBox: {
    width:'25%',
    "@media(max-width:700px)": {
      width: '100%',
      marginTop: '10px'
    },
      },  
  amountBox: {
        width:'20%',
        "@media(max-width:700px)": {
          width: '100%',
          marginTop: '10px'
        },
          },
  dividerStyle: {
    color: 'black'
  },
  uploadBtn: {
    border: '1px dashed',
    padding: '30px',
    borderRadius: '16px',
    height: '150px',
    marginTop: '12px'
  },
  imgCenter: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '16px'
  },
  uploadTxt: {
    textTransform: 'capitalize' as 'capitalize'
  },
  mainCenterBox : {
    display:"flex", 
    width:'100%',
    "@media(max-width:700px)": {
      display: 'block'
    },
  },
  boxRight:{
   width:'70%',
   "@media(max-width:1100px)": {
    width: "65%",
  },
  "@media(max-width:1000px)": {
    width: "60%",
  },
  "@media(max-width:900px)": {
    width: "55%",
  },
  "@media(max-width:800px)": {
      width: "50%",
    },
    "@media(max-width:700px)": {
      width: "100%",
    },
  },
  boxLeft:{
    width:'30%',
    "@media(max-width:1100px)": {
      width: "35%",
    },
    "@media(max-width:1000px)": {
      width: "40%",
    },
    "@media(max-width:900px)": {
      width: "45%",
    },
    "@media(max-width:800px)": {
      width: "50%",
    },
    "@media(max-width:700px)": {
      width: "100%",
    },    
      },
   experienceBox:{
    background: '#E6EBEF',
    padding: '20 20px 20px 16px',
    borderRadius: '16px',
    margin: '20px'
   },
   text2:{
    marginTop: '0px',
    borderRadius: '16px',
    fontFamily:"Rubik",
    marginLeft: '35px',color:'#0A0A0A'
   },
  lastBoxStyle: {
    border: '1px solid #e6e6e6',
    borderRadius: '0 0 6px 6px',
    padding: '10px'
  },
  btnStyle: {
    padding: '10px 60px',
    fontWeight: 600,
    borderRadius: '8px',
    backgroundColor: '#364BA0',
    fontSize: '18px',
    fontFamily:"Rubik",
    border: '1px solid #364BA0',
    '&:hover': {
      backgroundColor: '#364BA0'
    }
  },
  btnStyle2: {
    padding: '10px 30px 10px 30px',
    borderRadius: '8px',
    border: '1px solid #4A4A4A',
    color: '#4A4A4A',
    fontWeight: 600,
    fontSize: '18px',
    fontFamily:"Rubik",
    '&:hover': {
      border: '1px solid #364BA0'
    }
  },
  dialogtext: {
    color: '#2B2B2B',
    fontFamily: 'Rubik,Regular',
    fontSize: '36px',
    fontWeight: 700
  },
  dialogBox: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  textprg: {
    color: '#2B2B2B',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 400,
    marginTop: '15px'
  },
  reason: {
    color: '#2B2B2B',
    fontFamily: 'Inter',
    fontSize: '22px',
    fontWeight: 600,
    marginTop: '10px'
  },
  textprg2: {
    color: '#2B2B2B',
    fontFamily: 'Inter',
    fontSize: '20px',
    fontWeight: 400,
    marginTop: '10px'
  },
  favouriteBtn: {
    padding: '12px 50px 12px 50px',
    borderRadius: '8px',
    border: '1px solid #2676D8',
    color: '#2676D8',
    fontWeight: 600,
    '&:hover': {
      border: '1px solid #2676D8'
    }
  },
  applyBtn: {
    padding: '12px',
    borderRadius: '8px',
    backgroundColor: '#2676D8',
    marginLeft: '10px',
    '&:hover': {
      backgroundColor: '#2676D8'
    }
  },
  btngroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  },
  RadioGroupBox: {
    display: 'grid',
    gridColumnGap: '30px', // Column gap of 30px
    width: '100%', // Take up all available width,
  },
  php: {
    background: ' #E4F5FF',
    borderRadius: '30px',
    width: 'fit-content',
    padding: '5px 25px 5px 25px',
    color: '#364BA0',
    fontWeight: 600,
    fontFamily: 'Rubik',
    marginTop: '12px',
    marginRight: '5px',
    fontSize: '14px'
  },
  postTime: {
    color: '706F6F',
    fontSize: '14px',
    fontFamily: 'Rubik',
    marginTop: '12px',
    alignContent: 'center'
  },
  covertextfield: {
    fontSize: '16px',
    color: '#756e6e',
    fontFamily: 'Rubik',
    fontWeight: 400,
    "& .MuiOutlinedInput-root": {
      borderRadius: '10px',
      fontSize: '16px',
      color: '7#56e6e',
      fontFamily: 'Rubik',
      fontWeight: 400,
    }
  },
  addStyle: {
    fontFamily: "Rubik",
    fontSize: "24px",
    fontWeight: 500,
    color: "#364BA0"
  },
  blueButton:{
    backgroundColor: '#364BA0',
    height:"40px",
    borderRadius:"10px",
    borderColor: '#364BA0',
    padding:"22px",
    color: '#fff',
    fontSize:"18px",
    fontFamily:"Rubik",
    marginBottom: '10px',
    fontWeight:500,
    width: '50%',
    margin: '50px auto 15px'
  },
  transButton: {
    fontFamily:"Rubik",
  borderColor: '#4A4A4A',
  padding:"22px",
  fontSize:"18px",
  height:"40px",
  fontWeight:500,
  color: '#4A4A4A',
  borderRadius:"10px" 
  },
  endtext:{
    color: '#363636',
    fontSize: '16px',
    fontFamily: 'Rubik',
    fontWeight: 700,
   ' .MuiTypography-colorTextSecondary' :{
      color: 'black'
    },
    '.MuiTypography-root': {
      margin: 0,
      color: 'black'

    }
  },
  projectDesc: {
    fontSize: '16px',
    fontWeight: 400,
    fontFamily: 'Regular,Rubik',
    marginTop: '12px',
    marginBottom: '22px',
    color: '#0A0A0A',
    marginRight: '25px'
  },
  radiobtn:{
    color: "#979797", 
    "&.Mui-checked": {
      color: "#364BA0", 
    },
  }
};
const CustomDialogBox = styled(Dialog)({
  '& .MuiPaper-rounded':{
    width:"760px",
    borderRadius: "18px",
  }
})
const CustomCheckbox = styled(Checkbox)({
    padding: 0, 
  borderRadius: 5,
    color: '#979797', 
    '&.Mui-checked': {
      color: '#364BA0', 
    },
    '& .MuiSvgIcon-root': {
        borderRadius: '5px',
      },
  })
const InputField = styled(FormControl)({
  "&  .MuiOutlinedInput-input" :{
    color:'#979797 !important',
    fontFamily: 'Rubik'
  },
  "@media(max-width:500px)": {
    "&  .MuiOutlinedInput-input" :{
        padding: "11.5px 12px",
      }
    },

    "& .MuiListItem-button": {
      "& :hover":{
        textDecoration: "none",
        backgroundolor: "red",

      }
    }
});

export default withStyles(webStyle)(ViewSubmitProposal);
// Customizable Area End