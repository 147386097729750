import React from "react";
// Customizable Area Start
import {
  Box,
  Grid,
  Typography,
  styled
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import FooterWeb from "./Components/Footer.web";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

const theme = createTheme({
  palette: {
    primary: {
      main: "#206FC4",
    },
    secondary: {
      main: "rgb(96, 96, 96)",
    },
  },
  typography: {
    fontFamily: "Inter",
  },
});

  import FullWidthJobsTabController, {
    Props,
  } from "./FullWidthJobsTabController";
  import Freelance from './FreelancerOffers.web';
import FreelancerArchiveProposals from "./FreelancerArchiveProposals.web";
import FreelancerNavBar from "./FreelancerNavBar.web";
import FreelanceActive from "./FreelancerActive.web";
// Customizable Area End

export default class FullWidthTabProposals extends FullWidthJobsTabController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start
    const { selectedTab } = this.state;
    return (
      //Merge Engine DefaultContainer
      <>
        <ThemeProvider theme={theme}>
        <FreelancerNavBar navigation={this.props.navigation} />
        <CustomBox>
        <Box
          display={'grid'}
          gridAutoFlow={'row'}
          gridRowGap={'1rem'}
        >
          <Box display={'grid'} gridAutoFlow={'column'} width={'17%'}>
            <Typography
              style={{
                ...webStyles.text,

                fontSize: '20px',
                fontWeight: 700,
                marginBottom: '15px'
              }}
            >
              Find Work
            </Typography>
            <ChevronRightIcon style={{ marginTop: '4px' }} />
            <Typography
              style={{
                ...webStyles.text,

                fontSize: '20px',
                fontWeight: 700,
                color: '#364BA0'
              }}
            >
              My Proposals
            </Typography>
          </Box>
        </Box>
        </CustomBox>
        <CustomBox>
          <Grid container style={webStyles.mainConatiner}>
            <Grid item style={{ width: "100%" }}>
              <Box style={{ padding: "10px" }}>
                <Grid item xs={12} sm={6} md={3}>
                  <Box style={{ display: "inline-grid" }}>
                    <Typography style={webStyles.proposalHeading}>
                      My Proposals
                    </Typography>
                  </Box>
                </Grid>
                <CustomTabs value={this.state.activeTab || 0} onChange={this.handleTabChange} aria-label="custom tabs">
                <Tab className="tab"  label={`Offers Proposals (${this.state.offerProposalsData?.data?.attributes?.offers?.offer_count || 0})`} />
                <Tab className="tab"  label={`Active Proposals (${this.state.proposalsData?.proposals?.data?.length || 0})`} />
                <Tab className="tab"  label={`Archived Proposals (${this.state.archiveProposalsData?.data?.attributes?.archived_proposal?.archived_proposal_count || 0})`} />
              </CustomTabs>
                {this.state.activeTab === 0 && (
                  <Freelance
                    navigation={this.props.navigation}
                    proposalType={"offers"} activeProposalData={null} submittedProposalsData={null} invitationToInterviewData={null}  />
                )}
                  {this.state.activeTab === 1 && (
                  <FreelanceActive
                    navigation={this.props.navigation}
                    proposalType={"active"} activeProposalData={null} submittedProposalsData={null} invitationToInterviewData={null}  />
                )}
                   {this.state.activeTab === 2 && (
                   <FreelancerArchiveProposals
                   navigation={this.props.navigation}
                   proposalType={"archived"} activeProposalData={null} submittedProposalsData={null} invitationToInterviewData={null} />
               )}
              </Box>
            </Grid>
          </Grid>
          </CustomBox>
        </ThemeProvider>
        <FooterWeb />
      </>

      //Merge Engine End DefaultContainer
    );
    // Customizable Area End
  }
}

// Customizable Area Start

const webStyles = {
  mainConatiner: {
    // padding: "20px",
  },
  jobBtnBox: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap" as "wrap",
  },
  text: {
    color: '#0A0A0A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 300
  },
  jobText: {
    fontSize: "20px",
    fontFamily:"Inter"
  },
  myJobText: {
    color: "#206FC4",
    fontFamily:"Inter"
  },
  postBtn: {
    borderRadius: "7px",
    padding: "1% 2%",
    fontFamily:"Inter"
  },
  borderBox: {
    background: "#F5F7F9",
    padding: "15px",
    borderRadius: "5px",
    marginTop: "3%",
    overflow: "auto",
  },
  proposalHeading: {
    color: "#3F3F3F",
    fontFamily: "Inter",
    fontSize: "36px",
    fontWeight: 700,
    lineHeight: "44px",
    display: "flex",
    justifyContent: "center",
    marginBottom:"24px"
  },
};
const CustomTabs = styled(Tabs)(({ theme }) => ({
  '& .tabs-container': {
    width: '100%',
    fontFamily: 'Arial, sans-serif',
  },
  '& .tabs': {
    display: 'flex',
  },
  '& .tab': {
    fontFamily: 'Rubik',
    paddingLeft: '10px 20px',
    cursor: 'pointer',
    color: '#3F3F3F', 
    fontWeight: 700,
  fontSize: '16px',
  },
  '& .tab:hover': {
    color: '#364BA0',
  },
'& .tab:focus, & .tab:active': {
  color: '#364BA0',
  borderBottom: '3px solid #364BA0',
},
  '& .tab-content': {
    padding: '20px',
    marginTop: '10px',
  },
}));
const CustomBox = styled(Box)(({ theme }) => ({
  marginLeft: '100px',
  marginRight: '50px',
  marginTop: '52px',
  display: 'grid',
  gridAutoFlow: 'row',
  gridRowGap: '1rem',
  '@media (max-width: 700px)': {
    marginLeft: '45px',
  },
  '@media (max-width: 500px)': {
    marginLeft: '30px',
  },
}));
// Customizable Area End
