import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { ChangeEvent } from "react";
// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  onStepChange: (stepChange: number) => void
  getNameSet?: ((event: any) => void),
  getSkillsSet?: ((event: any) => void),
  getEstimateSet?: ((value: any) => void),
  getCategoryIdSet?: ((value: any) => void),
  getPerDayChargeSet?: ((value: number) => void),
  getBudgetSet?: ((value: any) => void),
  getDescriptionSet?: ((value: string) => void),
  getRoadMap?: (() => void),
  getDurationSet?:((value:any)=>void),
  skillsOption?: any,
  skillValue?: string,
  name?:string,
  handleSkillMenuInputChange?: ((_: any, value: any) => void)
  classes?: any,
  handleFileChange?:any
  files?:any;
  skillLabel?:any;
  describeError?:any;
  estimate?:any
  description?: string;
  duration?:any
  categoriesInfo?:any[];
  loading?:boolean
  fileError?:string
  handleRemoveFile?:((value:any)=>void)
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  dashboardData: {
    type: string;
    quantity: string;
  }[];
  totalCandidates: string;
  type: string;
  token: string;
  errorMsg: string;
  loading: boolean;
  name: any;
  estimate:string;
  activeTabValue: number;
  selectedFile: File | any;
  skillLable:any
  error:boolean
  skillError:boolean
  firstName:any
  isHovered: boolean
  checkbox1Checked: boolean,
  checkbox2Checked: boolean,
  checkbox3Checked: boolean,
  checkbox4Checked: boolean,
  checkbox5Checked: boolean,
  checkbox6Checked: boolean,
  jobTitle: string, 
  anchorEl: null,
  selectedSubCategories: any;
    // Customizable Area End
}
interface SS { }

export default class DashboardController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  apiDashboardItemCallId: string = "";
  dashboardApiCallId: string = "";
  apiGetQueryStrinurl: string = "";
  apiSearchSkillDataId: string="";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceSuccessMessage),
    ];

    this.state = {
      type: "",
      dashboardData: [],
      totalCandidates: "",
      errorMsg: "",
      token: "",
      loading: false,
      name: "",
      estimate:"large",
      activeTabValue: 0,
      selectedFile: null,
      skillLable:[],
      error:false,
      skillError:false,
      firstName: localStorage.getItem("accountHolderName"),
      isHovered:false,
      checkbox1Checked: true,
      checkbox2Checked: false,
      checkbox3Checked: false,
      checkbox4Checked: false,
      checkbox5Checked: false,
      checkbox6Checked: false,
      jobTitle: "", 
      anchorEl: null,
      selectedSubCategories: {}
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    const webHeader = {};
    const webRequestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.apiDashboardItemCallId = webRequestMessage.messageId;
    webRequestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      webConfigJSON.dashboardGetUrl
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(webHeader)
    );

    webRequestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
    runEngine.sendMessage(webRequestMessage.id, webRequestMessage);
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const webApiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let webResponseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let webErrorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (webResponseJson && !webResponseJson.errors) {
      } else if (webResponseJson && webResponseJson.errors) {
        if (webApiRequestCallId === this.apiDashboardItemCallId) {
          this.setState({
            errorMsg: webErrorReponse,
            loading: false
          });
        }
        else if (webApiRequestCallId===this.apiSearchSkillDataId){          
          this.setState({skillLable:webResponseJson.skills})
        }
      }
    }
    // Customizable Area End
  }
  // Customizable Area Start
  handleRedirect = () => {
    const msg = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), "SearchTalent");
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  
  
   handleAddBillingMethod2 = () => {
    this.props.navigation.navigate();
  };
  handleBack2 = () => {
    this.props.navigation.navigate();
  };
  handleAddBillingMethod = () => {
    this.props.navigation.navigate("CustomizedSteppers");
  };
  handleBack = () => {
    this.props.onStepChange(-1)
  };
  handleContinue = () => {
    if(this.props.skillValue==""){
    this.setState({skillError:true})
    }
    else{

      this.props.onStepChange(+1)
    }
  };
  handleTitleBack = () => {
    this.props.onStepChange(-1)
  };
 
  handleTitleContinue = () => {
    if(this.props.name==""){
      this.setState({error:true})
      }
      else{
  
        this.props.onStepChange(+1)
      }
  };
  
  handleWorkScopeBack = () => {
    this.props.onStepChange(-1)
  };
  handleWorkScopeContinue = () => {
    this.props.onStepChange(1)
  };
  handleChooseCategoryBack = () => {
    this.props.onStepChange(-1)
  };

  handleApply = () => {
    const { categoriesInfo, getCategoryIdSet, onStepChange } = this.props;
    const { activeTabValue } = this.state;
    if (categoriesInfo && categoriesInfo.length > 0) {
        const selectedCategory = categoriesInfo[activeTabValue];

        if (selectedCategory) {
            if (getCategoryIdSet) {
                getCategoryIdSet(selectedCategory); 
            } 
        } 
    } 
    onStepChange(1);
}
  handleConversationBack = () => {
    this.props.onStepChange(-1)
  }
  handlePostThisJob = () => {
    this.props.navigation.navigate("ViewJob")
  }
  handleKeyPress = (event: any) => {
    const allowedChars = ["'", "\"", "(", ")", "&"];
    const input = (event.target && event.target.value) || '';  // Safe check for event.target
  
    if (input.length === 0 && event.key === " ") {
      event.preventDefault();
      return;
    }
    if (
      (event.key >= "A" && event.key <= "Z") ||
      (event.key >= "a" && event.key <= "z") ||
      (event.key >= "0" && event.key <= "9") ||
      allowedChars.includes(event.key) ||
      (event.key === " " && input.length > 0)
    ) {
      return true;
    } else {
      event.preventDefault();
    }
  };
  
  
  
  handleTabsChange = (event: any, newValue: any) => {
    this.setState({ activeTabValue: newValue });
  };

  handleInput = (event: any) => {
    let inputValue = event.target.value;
    let sanitizedInput = inputValue.replace(/[^a-zA-Z0-9'"\(\)& ]/g, '');
    const maxLength = 200;
    if (sanitizedInput.length > maxLength) {
      sanitizedInput = sanitizedInput.slice(0, maxLength);
      this.setState({
        error: true, 
      });
    } else {
      this.setState({
        error: false, 
      });
    }
    event.target.value = sanitizedInput;
    this.setState({
      jobTitle: sanitizedInput,
    });
  };
  
  
  handleClear = () => {
    this.setState({
      selectedSubCategories: {}, 
    });
  };
  handleHover = () => {
    this.setState({ isHovered: true });
  };
  
  handleLeave = () => {
    this.setState({ isHovered: false });
  };
  handleCheckboxChange = (e: any, parentId: number, subCategoryIds: number[]) => {
    const { checked } = e.target;
    
    this.setState((prevState) => {
      return {
        selectedSubCategories: {
          ...prevState.selectedSubCategories,
          [parentId]: checked ? subCategoryIds : [],
        },
      };
    });
  };

  


  // Customizable Area End
}
