import React from "react";
//Customizable Area Start
import { Box, Button, Chip, CircularProgress, styled, Typography } from "@material-ui/core";
import FreelancerOffersController, {
  Props,
} from "./FreelancerOffersController.web";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Rating from "@material-ui/lab/Rating";

//Customizable Area End
export default class FreelanceActive extends FreelancerOffersController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    // Customizable Area End
  }
  render() {
//Customizable Area Start
 interface ProposalData
  {
      id: string,
      type: string,
      attributes: {
          id: number,
          post_id: number,
          created_at: string,
          updated_at: string,
          accepted_at: null,
          rejected_at: null,
          post: {
              data: {
                  id: string,
                  type: string,
                  attributes: {
                      id: number,
                      name: string,
                      description: string,
                      created_at:string,
                      updated_at: string,
                      posted_job: number
                  }
              }
          },
          days_ago: string,
      }
  }

const { activeProposalData } = this.state;

if (!activeProposalData) {
  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <CircularProgress />
    </Box>
  );
}
//Customizable Area End
    return (
//Customizable Area Start
<>
<CustomBox>
        <Box style={{width:'70%'}}>
        {activeProposalData.length > 0 ? activeProposalData?.map((item: any, index: number) => {
            return(
              <>
                  <CustomCard><Card><CardContent><Typography
                         style={{ ...webStyle.text,fontSize: '20px',fontWeight: 700,color: '#303030'
                         }}
                       >
                        {item?.attributes?.post_attributes?.data?.attributes?.name}
</Typography><Box display={'flex'} alignItems={'center'}>
                   <LocationOnIcon style={{marginLeft: '-5px', marginRight: '3px' }} />
                   <Typography style={{  ...webStyle.text,  marginTop: '10px',  display: 'contents',}}>
                 {item?.attributes?.post_attributes?.data?.attributes?.location}</Typography> 
                   <Rating 
                   name="simple-controlled" value={3} disabled
                     style={{padding: '8px 0px',
                       marginLeft: '7px',
                       marginRight: '15px',
                       width: '90px',
                     }}/>
                   <Typography style={{ ...webStyle.text, 
                    marginLeft: '20px' }}>
                     3/5
                   </Typography>
                 </Box>
                 <Box 
                 display={'flex'} style={{ 
                   marginTop: '12px' ,
                           alignItems: 'center', }}>
                         <Typography style={{
                          fontSize: '14px', 
                          marginRight: '5px',
                         fontFamily: 'Rubik', 
                         }}>
                          Est. Time:
                          {''}
                         </Typography>
                         <Typography style={{fontSize: '14px' , 
                           fontFamily: 'Rubik'}}>
                             {item?.attributes?.post_attributes?.data?.attributes?.project_length}</Typography>
                         </Box>
                         <Box style={{ marginTop: '10px', 
                          marginBottom: '24px' }}>
                         <Typography 
                         style={{
                           fontFamily: 'Rubik', 
                          fontSize: '14px', 
                         color: '#6b6969'}}>
                          {item?.attributes?.post_attributes?.data?.attributes?.job_description}
                          </ Typography>
                       </Box>
                       <Box>
                        {item?.attributes?.post_attributes?.data?.attributes?.skills.map(
                           (item:
                            | React.ReactPortal
                            | null
                            | React.ReactChild
                            | undefined
                            | boolean
                               | React.ReactFragment) =>(
                             <Chip label={item} style={webStyle.chiplabel}
                             />))}
                             </Box>
                             </CardContent>
                     <Box>
                     <Box 
                     display={'flex'}>
                       <Typography style={{fontSize: '16px', fontFamily: 'Rubik', whiteSpace: 'nowrap', 
                          margin: '0px 5px' 
                       }}>
                        Project Budget: 
                        
                        {''}</Typography>
                       <Typography 
                       style={{fontSize: '16px', 
                       fontFamily: 'Rubik'}}>

                        ${item?.attributes?.post_attributes?.data?.attributes?.per_day_charge}
                       </ Typography> </Box>
                       <ProposalCount>
                       <Typography style={{
                         fontFamily: 'Rubik',  
                         whiteSpace: 'nowrap', 
                         margin: '0px 5px' ,
                         color: '#6b6969',
                        fontSize: '14px', 
                                         }}
                                         >
                         Proposals: 
                         {''}
                       </Typography >
                       <Typography 
                       style={{fontSize: '16px', 
                        color: '#6b6969',
                        fontFamily: 'Rubik,',
                       }} >
                        ${item?.attributes?.post_attributes?.data?.attributes?.proposals_count} </Typography></ProposalCount>
                     </Box>
                     </Card>
     
                       <CardFooter  >
                        <Box style={{alignContent: 'end'}}>
                         <Typography style={{ ...webStyle.text, 
                                              fontWeight: 400, 
                                              fontFamily: 'Rubik',
                                              alignContent: 'end'}}>
                            {item?.attributes?.post_attributes?.data?.attributes?.created_at}
                         </Typography>
                        </Box>
                 
                        <CustomButton>
                       <Button
                          onClick={() => 
                            this.props.navigation.navigate('ViewSubmitProposal',{id:item.id})
                           } 
                        data-testid="reject-0" 
                         style={{ cursor: 'pointer' , 
                                  border: '1px solid #364BA0', 
                                  color:'364BA0',
                                  padding: '7px 30px',
                                 marginRight: '10px',
                                 borderRadius: '12px',
                                 fontWeight: 700,
                                 height: '44px',
                                 width: 'auto'
                                 }}
                       >
                        WITHDRAW PROPOSALS
                       </Button>
     
                         <Button
                           variant="contained"
                           style={{ backgroundColor: '#364BA0', color: 'FFF' ,
                             height: '44px',
                             width: '200px',
                             borderRadius: '12px', fontWeight: 700, whiteSpace: 'nowrap'
                           }}
                           data-testid="accept-0" 
                           onClick={() => 
                            this.props.navigation.navigate('ViewSubmitProposal',{id:item.id})
                           } 
                         >
                          CHANGE TERMS
                         </Button>
     
                       </CustomButton>
                       </CardFooter>
                   </CustomCard>
              </>
            )
  }):<Typography style={{
    fontSize: '19px',
      justifyContent: 'center',
      display: 'flex',
      color: '#878484',
      fontFamily: 'Rubik',
      fontWeight: 700
  }}>No active proposals found</Typography>
  }
        </Box>
      </CustomBox>
                  </>
//Customizable Area End
    );
  }
}

//Customizable Area Start
const webStyle = {
  text: {
    color: '#0A0A0A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 300
  },
  chiplabel: {
    fontWeight: 700,
    marginRight: '10px',
    color: '#364BA0',
    backgroundColor: '#E4F5FF',
    fontSize: '16px', 
    padding: '12px',
    '& .MuiChip-label': {
      textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '16px', 
    }
  },
  tableContent: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
  },
  date: {
    color: "#434343",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "28px",
  },
  jobname: {
    color: "#206FC4",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 500,
    display: "flex" as "flex",
    justifyContent: "center" as "center",
    alignItems: "center" as "center",
    textAlign: "center" as "center",
  },
  startDateText: {
    color: "#434343",
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "18px",
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    marginTop:"14px"
},
noProfile:{
  fontSize:'1.5rem',
  paddingTop:'5%',
  textAlign:'center' as 'center',
  color:'grey'
}
};
const CustomBox = styled(Box)(({ theme }) => ({
  marginRight: '50px',
  marginTop: '52px',
  display: 'grid',
  gridAutoFlow: 'row',
  gridRowGap: '1rem',
  '@media (max-width: 700px)': {
    marginLeft: '45px',
  },
  '@media (max-width: 500px)': {
    marginLeft: '30px',
  },
}));

const CardContent = styled(Box)(({ theme }) => ({
  width: '70%',
 '@media (max-width: 450px)': {
   width: '100%',
   marginBottom: '30px'
 },
}));

const ProposalCount = styled(Box)(({ theme }) => ({
  marginTop: '5px',
  justifyContent: 'end',
  display: 'flex',
  '@media (max-width: 700px)': {
    justifyContent: 'start'
  },
}));

const CustomCard = styled(Box)(({ theme }) => ({
  borderRadius: '12px',
  backgroundColor: 'rgb(230, 235, 239)',
  padding: '24px',
  width: '100%',
  marginBottom: '24px'
}));

const CustomButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  '@media (max-width: 450px)': {
   display: 'block',
  },
 }));

 const Card = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  '@media (max-width: 700px)': {
    display: 'block'
  },
}));

const CardFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '10px',
  '@media (max-width: 700px)': {
    display: 'block',
  },
}));
//Customizable Area End