//Customizable Area Start
import React from "react";
import {  Box, Button, Chip, styled, Typography } from "@material-ui/core";
import FreelancerOffersController, {
  Props,
} from "./FreelancerOffersController.web";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Rating from "@material-ui/lab/Rating";
// Customizable Area End

export default class FreelancerArchiveProposals extends FreelancerOffersController {
  constructor(props: Props) {
    super(props);
    //Customizable Area Start
    // Customizable Area End
  }
  //Customizable Area Start
  render() {
    const { archiveInterviewData } = this.state;
    const archivedProposals = archiveInterviewData?.archived_proposals?.data?? [];
    return (
      <>
      <div style={{ margin: "16px" }}>
      <CustomBox>
        <Box style={{width:'70%'}}>
        {archivedProposals.length > 0 ? archivedProposals?.map((item: any, index: number) => {
          return (
            <><CustomCard>
            <Card>
                <CardContent><Typography
                    style={{...webStyle.text,fontSize: '20px',fontWeight: 700,color: '#303030'}}>
                     {item?.attributes?.post?.data?.attributes?.name}
                     </Typography>
                  <Box 
                  display={'flex'} 
                  alignItems={'center'}
                  >
                  <LocationOnIcon 
                  style={{
                    marginRight: '3px' ,
                 marginLeft: '-5px', 
                 }}/>
              <Typography 
              style={{ 
                      ...webStyle.text, 
                display: 'contents',
                marginTop: '10px', 
                 }} >{item?.attributes?.post?.data?.attributes?.country?.name}</Typography> 
              <Rating 
              name="simple-controlled" 
              value={3} 
              disabled
                style=
                {{
                  padding: '8px 0px',
                  marginLeft: '7px',
                  marginRight: '15px',
                  width: '90px',
                }} />
              <Typography 
              style={{ ...webStyle.text, 
              marginLeft: '20px' 
              }}>
                2.91/5</Typography></Box>
             <Box 
             display={'flex'} 
             style={{ 
                 alignItems: 'center', 
                 marginTop: '12px' 
                  }}>
                    <Typography 
                    style={{
                      fontFamily: 'Rubik', 
                      marginRight: '5px',
                      fontSize: '14px', 
                    }}>
                     Est. Time:{''}</Typography>
                    <Typography 
                    style= {{
                      fontFamily: 'Rubik',
                      fontSize: '14px' , 
                      }}
                      >{item?.attributes?.post?.data?.attributes?.project_length}</Typography>
                    </Box>
                    <Box 
                    style={{ marginTop: '10px', 
                    marginBottom: '24px' 
                    }}>
                    <Typography 
                    style={{
                     fontSize: '14px', 
                     color: '#6b6969',
                    fontFamily: 'Rubik', 
                    }}>
                     {item?.attributes?.post?.data?.attributes?.job_description}
                     </Typography>
                  </Box>
                  <Box>{item?.attributes?.post?.data?.attributes?.skills?.map(
                       (item :
                        | undefined
                        | boolean
                        | React.ReactFragment
                        | React.ReactPortal
                        | null
                        | React.ReactChild
                       )=> (
                        <Chip label={item} style={webStyle.chiplabel}/>))}
                        </Box></CardContent>
                <Box><Box display={'flex'} >
                  <Typography style={{fontSize: '16px', fontFamily: 'Rubik', 
                  margin: '0px 5px' ,
                  whiteSpace: 'nowrap', 
                  }}>
                    Job is Closed
                    </Typography>
                </Box>
                </Box>  
                </Card>
                <CardFooter>
                  <Box style={{alignContent: 'end'}} >
                    <Typography style={{ ...webStyle.text,
                    fontWeight: 400, 
                    fontFamily: 'Rubik',
                     alignContent: 'end',
                     }}>
                      {item?.attributes?.post?.data?.attributes?.created_at}
                    </Typography>
                     </Box><CustomButton>
                    <Button onClick={() => this.props.navigation.navigate('ViewSubmitProposal',{id:item.id})} 
                    style={{ 
                      padding: '7px 30px',
                      border: '1px solid #364BA0', 
                      marginRight: '10px',
                      width: '200px',
                      borderRadius: '12px',
                      color:'364BA0',
                      fontWeight: 700,
                      height: '44px',
                      cursor: 'pointer' , 
                            }}
                   data-testid="reject-0" >
                   VIEW DETAILS
                  </Button>

                  </CustomButton>
                  </CardFooter>
              </CustomCard>
         </>
            )
  }):<Typography style={{
    fontSize: '19px',
      justifyContent: 'center',
      display: 'flex',
      color: '#878484',
      fontFamily: 'Rubik',
      fontWeight: 700
  }}>No archived proposals found</Typography>
  }
  {this.state.rejectInterviewData?.map((itm)=>{
    return(
      <><CustomCard>
      <Card>
          <CardContent><Typography
              style={{...webStyle.text,fontSize: '20px',fontWeight: 700,color: '#303030'}}>
               {itm?.attributes?.post_attributes?.data?.attributes?.name}
               </Typography>
            <Box 
            display={'flex'} 
            alignItems={'center'}
            >
            <LocationOnIcon 
            style={{
              marginRight: '3px' ,
           marginLeft: '-5px', 
           }}/>
        <Typography 
        style={{ 
                ...webStyle.text, 
          display: 'contents',
          marginTop: '10px', 
           }} >{itm?.attributes?.post_attributes?.data?.attributes?.country?.name}</Typography> 
        <Rating 
        name="simple-controlled" 
        value={3} 
        disabled
          style=
          {{
            padding: '8px 0px',
            marginLeft: '7px',
            marginRight: '15px',
            width: '90px',
          }} />
        <Typography 
        style={{ ...webStyle.text, 
        marginLeft: '20px' 
        }}>
          2.91/5</Typography></Box>
       <Box 
       display={'flex'} 
       style={{ 
           alignItems: 'center', 
           marginTop: '12px' 
            }}>
              <Typography 
              style={{
                fontFamily: 'Rubik', 
                marginRight: '5px',
                fontSize: '14px', 
              }}>
               Est. Time:{''}</Typography>
              <Typography 
              style= {{
                fontFamily: 'Rubik',
                fontSize: '14px' , 
                }}
                >{itm?.attributes?.post_attributes?.data?.attributes?.project_length}</Typography>
              </Box>
              <Box 
              style={{ marginTop: '10px', 
              marginBottom: '24px' 
              }}>
              <Typography 
              style={{
               fontSize: '14px', 
               color: '#6b6969',
              fontFamily: 'Rubik', 
              }}>
               {itm?.attributes?.post_attributes?.data?.attributes?.job_description}
               </Typography>
            </Box>
            <Box>
             {itm?.attributes?.post_attributes?.data?.attributes?.skills?.map(
                (item:
                 | React.ReactChild
                 | React.ReactPortal
                 | null
                 | React.ReactFragment
                 | undefined
                 | boolean
                 ) =>(
                  <Chip 
                  label={item}
                  style={webStyle.chiplabel}
                  />
                  ))}
                  </Box>
                  </CardContent>
          <Box>
           <Box 
           display={'flex'}>
            <Typography 
            style={{fontSize: '16px', 
            fontFamily: 'Rubik', 
            whiteSpace: 'nowrap', 
            margin: '0px 5px' 
            }}>Job is Closed</Typography>
          </Box></Box>  </Card>
          <CardFooter><Box 
                       style={{
                         alignContent: 'end'
                         }}>
              <Typography 
              style={{ ...webStyle.text,fontWeight: 400, fontFamily: 'Rubik',
               alignContent: 'end'
               }}>{itm?.attributes?.post_attributes?.data?.attributes?.created_at}
              </Typography> </Box>
             <CustomButton>
              <Button 
              onClick={() => 
              this.props.navigation.navigate('ViewSubmitProposal',{id:itm.id})
             } 
              style={{ 
                width: '200px',
                border: '1px solid #364BA0', 
                padding: '7px 30px',
                marginRight: '10px',
                borderRadius: '12px',
                color:'364BA0',
                fontWeight: 700,
                height: '44px',
                cursor: 'pointer' , 
                      }}
             data-testid="reject-0" >
             VIEW DETAILS
            </Button>

            </CustomButton>
            </CardFooter>
        </CustomCard>
   </>
    )
  })}
        </Box>
      </CustomBox>
      </div>

      </>
    );
  }
}
const webStyle = {
  tableContent: {
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    textAlign: "center",
  },
  chiplabel: {
    fontWeight: 700,
    marginRight: '10px',
    color: '#364BA0',
    backgroundColor: '#E4F5FF',
    fontSize: '16px', 
    padding: '12px',
    '& .MuiChip-label': {
      textTransform: 'uppercase',
    fontWeight: 700,
    fontSize: '16px', 
    }
  },
  text: {
    color: '#0A0A0A',
    fontFamily: 'Inter',
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 300
  },
  activeBtn: {
    background: "#206FC4",
    width: "275px",
    height: "50px",
    borderRadius: "25px",
    color: "#FFFFFF",
    fontFamily: "Inter,Rubik" as "Inter,Rubik",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none" as "none",
  },
  inactivbt: {
    fontFamily: "Inter,Rubik" as "Inter,Rubik",
    fontSize: "20px",
    fontWeight: 500,
    lineHeight: "24px",
    textTransform: "none" as "none",
    color: "#434343",
    marginTop: "10px",
  },
  dateArchive: {
    color: "#434343",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 400,
    lineHeight: "28px",
  },
  jobname: {
    color: "#206FC4",
    fontFamily: "Inter",
    fontSize: "18px",
    fontWeight: 500,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  startDateText: {
    color: "#434343",
    fontFamily: "Inter",
    fontWeight: 500,
    fontSize: "18px",
  },
};

const CustomBox=styled(Box)(({ theme })=>({
  gridAutoFlow: 'row',
  display: 'grid',
  marginTop: '52px',
  marginRight: '50px',
  gridRowGap: '1rem',
   '@media (max-width: 700px)' :{
    marginLeft: '45px'
  },
  '@media (max-width: 500px)' :{
    marginLeft: '30px'
},}));

const CardContent=styled(Box)(({ theme })=>({
  width :'70%',
 '@media (max-width: 450px)' :{
   marginBottom: '30px',
   width: '100%',},
}));

const CustomCard=styled(Box)(({ theme })=>({
  marginBottom: '24px',
  padding: '24px',
  backgroundColor: 'rgb(230, 235, 239)',
  width: '100%',
  borderRadius: '12px',
}));

const CustomButton=styled(Box)(({ theme })=>({
  display:'flex' ,
  '@media (max-width: 450px)':{
   display: 'block' ,
  },}));

 const ProposalCount = styled(Box)(({ theme }) => ({
  marginTop: '10px',
  justifyContent: 'end',
  display: 'flex',
  '@media (max-width: 700px)': {
    justifyContent: 'start'
  },
}));
 
 const Card = styled(Box)(({ theme }) => ({
   display: 'flex',
   justifyContent: 'space-between',
   '@media (max-width: 700px)': {
     display: 'block'
   },
 }));
 
 const CardFooter = styled(Box)(({ theme }) => ({
   display: 'flex',
   justifyContent: 'space-between',
   marginTop: '10px',
   '@media (max-width: 700px)': {
     display: 'block',
   },
 }));
// Customizable Area End
