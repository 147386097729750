import React from "react";

// Customizable Area Start
import {
  Step,
  StepLabel,
  Box,
} from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import StepConnector from "@material-ui/core/StepConnector";
import { StepIconProps } from "@material-ui/core/StepIcon";
import { createTheme, styled, ThemeProvider, withStyles } from "@material-ui/core/styles";
export const config = require("./config");
import Title from "../../dashboard/src/Components/Title.web";
import ChooseCategory from "../../dashboard/src/Components/ChooseCategory.web";
import MainSkills from "../../dashboard/src/Components/MainSkill.web";
import StartConversation from "../../dashboard/src/Components/StartConversation.web";
import WorkScope from "../../dashboard/src/Components/WorkScope.web";
import BudgetRange from "./BudgetRange.web";
import CreatePost from "./CreatePost.web";
import { Horizontalinventohub } from "./assets";
import Location from "../../dashboard/src/Location.web";


export class ColorlibStepIcon extends React.Component<StepIconProps> {
  render() {
    const { active, completed } = this.props;
    const icons: { [index: string]: React.ReactElement } = {
      "1": <Box>1</Box>,
      "2": <Box>2</Box>,
      "3": <Box>3</Box>,
      "4": <Box>4</Box>,
      "5": <Box>5</Box>,
      "6": <Box>6</Box>,
      "7": <Box>7</Box>,
      "8": <Box>8</Box>,
    };

    return (
      <div
        data-testid="stepper"
        style={{ ...webStyle.stepIcon, background: active || completed ? '#3180C3' : '#FFF', color: active || completed ? '#fff' : '#B4CDEA' }}
      >
        {icons[String(this.props.icon)]}
      </div>
    );
  }
}

const ResponsiveImage = styled('img')(() => ({
  width: "300px",
  height: "61px",
  objectFit: "cover",
  marginTop: "25px",
  marginLeft: "22px",
  position: "absolute",
  "@media (max-width: 1100px)": {
    width: "280px", 
    position: "static", 
  },
  "@media (max-width: 600px)": {
    width: "150px", 
    position: "static", 
  },
}));

const StepperBox = styled(Box)({
  '@media (max-width: 500px)': {
    '& .MuiStepper-root': {
      paddingInline: '0px !important'
    },
    '& .MuiStepLabel-iconContainer > div': {
      width: '24px !important',
      height: '24px !important',
    },
    '& .MuiStep-alternativeLabel': {
      paddingInline: '4px !important'
    }
  }
})

// Customizable Area End

import CustomizedSteppersController, { Props } from "./CustomizedSteppersController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#206FC4"
    }
  },
  typography: {
    fontFamily: "Inter"
  }
});

export default class CustomizedSteppers extends CustomizedSteppersController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  // Customizable Area Start
  getStepContent = (step: number,categoriesInfo:any) => {
    switch (step) {
      case 0:
        return <CreatePost data-testid="create-post" {...this.props} onStepChange={this.onStepChange} getEmploymentTermSet={this.getEmploymentTermSet} valueTerm={this.state.employment_term}/>;
      case 1:
        return <Title data-testid="title" {...this.props} onStepChange={this.onStepChange} getNameSet={this.getNameSet} name={this.state.name}/>
      case 2:
        return <MainSkills data-testid="main-skills"  {...this.props} skillLabel={this.state.skillsLabel} handleSkillMenuInputChange={this.handleSkillMenuInputChange} skillValue={this.state.skillValue} skillsOption={this.state.originalSkillsOption} onStepChange={this.onStepChange}/>
      case 3:
        return <WorkScope data-testid="work-scope" {...this.props} onStepChange={this.onStepChange} getEstimateSet={this.getEstimateSet} getDurationSet={this.getDurationSet} estimate={this.state.estimate} duration={this.state.duration}/>
      case 4:
        return <ChooseCategory data-testid="choose-category" {...this.props} categoriesInfo={categoriesInfo} onStepChange={this.onStepChange} getCategoryIdSet={this.getCategoryIdSet}/>;
      case 5:
        return <Location data-testid="Location"  {...this.props} onStepChange={this.onStepChange} getLoction={this.getLocation} getCities={this.getCities}/>
        case 6:
          return <BudgetRange data-testid="budget-range" {...this.props} onStepChange={this.onStepChange} getPerDayChargeSet={this.getPerDayChargeSet} getBudgetSet={this.getBudgetSet} budgetTerm={this.state.budget} perDayTerm={this.state.per_day_charge}/>;
      case 7:
          return <StartConversation data-testid="start-conversation" {...this.props} onStepChange={this.onStepChange} 
          describeError={this.state.describeError} handleFileChange={this.handleFileChange} 
          name={this.state.name} description={this.state.description} 
          getDescriptionSet= {this.getDescriptionSet} 
          getRoadMap={this.handleError}
          loading={this.state.loading}
          files={this.state.files}
          handleRemoveFile = {this.handleRemoveFile}
          fileError= {this.state.fileError}
          />;
      default:
        return <CreatePost data-testid="create-post" {...this.props} onStepChange={this.onStepChange} getEmploymentTermSet={this.getEmploymentTermSet}/>;
    }
  }
  // Customizable Area End
  render() {
    const steps = this.getSteps();
    return (
      // Customizable Area Start

      // Required for all blocks
      <ThemeProvider theme={theme}>
        <Box>
        <ResponsiveImage src={Horizontalinventohub} onClick={this.handleLogoClick} data-test-id="handleLogoClick"/>
          <StepperBox style={webStyle.root}>
            <Stepper alternativeLabel activeStep={this.state.activeStep} connector={<ColorlibConnector />} style={{maxWidth:"445px",width:"100%",margin:"auto"}}>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel StepIconComponent={ColorlibStepIcon}></StepLabel>
                </Step>
              ))}
            </Stepper>
            {this.getStepContent(this.state.activeStep,this.state.categoriesInfo)}
          </StepperBox>
        </Box>
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  root: {
    width: "100%",
  },
  outerContainer: {
    display: 'flex'
  },
  invento: {
    width:"300px",
    height:"61px",
    objectFit:"cover",
     marginTop: "25px",
     marginLeft:"22px",
     position: "absolute" 
  },
  stepIcon: {
    backgroundColor: "#FFF",
    zIndex: 1,
    color: "#B4CDEA",
    width: 30,
    height: 30,
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    border: "1px solid #B4CDEA",
  },
  active: {
    background: "#B4CDEA",
    color: "#fff",
  },
  completed: {
    background: "#B4CDEA",
    color: "#fff",
  },
};

const ColorlibConnector = withStyles({
  alternativeLabel: {
    top: 17,
    '@media (max-width: 500px)': {
      top: 13
    }
  },
  active: {
    "& $line": {
      background: "#B4CDEA",
    },
  },
  completed: {
    "& $line": {
      background: "#B4CDEA",
    },
  },
  line: {
    border: "1px dashed #B4CDEA",
    borderRadius: 1,
  },
})(StepConnector);

// Customizable Area End
