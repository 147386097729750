import { IBlock } from 'framework/src/IBlock';
import { Message } from 'framework/src/Message';
import { BlockComponent } from 'framework/src/BlockComponent';
import { runEngine } from 'framework/src/RunEngine';
import MessageEnum, { getName } from 'framework/src/Messages/MessageEnum';
import { ChangeEvent } from 'react';
// Customizable Area Start
import storage from 'framework/src/StorageProvider';

// Customizable Area End
export const webConfigJSON = require('./config.js');

export interface Props {
  navigation: any;
  // Customizable Area Start
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  rating: any;
  feedBackText: any;
  search: any;
  mostRecent: any;
  progressValue: any;
  open: any;
  authToken: string;
  jobSavePopup: boolean;
  clientJobPostData: any[];
  favouritesJobIds:any[]
  anchorEl: null,
  selectedOption: string;
  currentPage:number;
  toggelScopeOfWork: boolean;
  toggleProjectBudget: boolean;
  toggleProposals: boolean;
  toggleEnglishLanguage: boolean;
  toggleOtherLanguage: boolean;
  totalPages:number;
  clientDetails: any
  countryList:any[];
  selectedFilters: any
  selectedDuration: string;
  loading: boolean;

  // Customizable Area End
}
interface SS {
  // Customizable Area Start
  // Customizable Area End
}

export default class SearchJobController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  clientJobPostId: string;
  clientDetailsId: string
  saveforLaterJobsId:string;
  showFavouritesId:string;
  countryListID:string = "";

  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    console.disableYellowBox = true;
    // Customizable Area Start

    this.subScribedMessages = [getName(MessageEnum.RestAPIResponceMessage)];
    this.clientJobPostId = '';
    this.clientDetailsId = "";
    this.saveforLaterJobsId="";
    this.showFavouritesId="";

    this.state = {
      rating: 2,
      feedBackText: '',
      search: '',
      mostRecent: '',
      progressValue: '10',
      open: false,
      authToken: '',
      jobSavePopup: false,
      clientJobPostData: [],
      favouritesJobIds:[],
      anchorEl: null,
      selectedOption: 'Most Recent',
      currentPage:1,
      toggelScopeOfWork: true,
      toggleProjectBudget: true,
      toggleProposals: true,
      toggleEnglishLanguage: true,
      toggleOtherLanguage: true,
      totalPages:0,
      clientDetails: [],
      countryList:[],
      selectedFilters: {
        proposal: '',
        duration: '', 
        budget: '', 
        country: ''
      },
      selectedDuration: '',
      loading: false
    };

    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.setState({
      authToken: await storage.get('authToken')
    });
    this.fetchClientJobPostDetails();
    this.fetchShowFavourites();
    this.fetchClientDetails()
    this.getCountriesList()

    // Customizable Area End
  }
  // Customizable Area Start
  componentDidUpdate(prevProps: any, prevState: any) {
    // Check if the state data has been updated
    if ((prevState.search !== this.state.search)||(prevState.currentPage !== this.state.currentPage)|| (prevState.selectedOption!=this.state.selectedOption)){
      // State search has been updated, you can perform actions here
      this.fetchClientJobPostDetails();
    }
  }
  handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    this.setState(({
      [name]: value
    } as unknown) as Pick<S, keyof S>);
  };
  handleClickOpen = () => {
    const profileStatus = this.state.clientDetails?.current_user?.profile_status || {}
    if (!profileStatus.basic_details) {
      this.props.navigation.navigate("CustomForm");
    } else if (!profileStatus.experience) {
      this.props.navigation.navigate("CustomFormExperience");
    } else if (!profileStatus.expertise) {
      this.props.navigation.navigate("ExpertiseAndFeesForm");
    } else if (!profileStatus.patents) {
      this.props.navigation.navigate("PatentsForm");
    } else if (!profileStatus.achievements) {
      this.props.navigation.navigate("AchievementsCustomForm");
    } else {
      this.props.navigation.navigate("FreelancerHomePage");
    }
  };

  handleClose = () => {
    this.setState({ open: false });
  };
  handleClick = (event: { currentTarget: any; }) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleCloseMenu = (option: string) => {
    this.setState(
      { anchorEl: null, selectedOption: option },
      () => {
        this.fetchClientJobPostDetails();
      }
    );
  };
formatDate(utcDateString: string): { date: string, time: string } {
    // Convert UTC date string to Date object
    const utcDate: Date = new Date(utcDateString);
  
    // Define options for formatting date
    const dateOptions: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    };
    // Define options for formatting time
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };

    // Format the date
    const formattedDate: string = utcDate.toLocaleDateString('en-US', dateOptions);

    // Format the time
    const formattedTime: string = utcDate.toLocaleTimeString('en-US', timeOptions);
    // Return the formatted date and time
    return { date: formattedDate, time: formattedTime };
}
  
fetchClientJobPostDetails = () => {
  const ClientJobPostDetailsRequest = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  const header = {
    'Content-Type': 'application/json',
    token: this.state.authToken,
  };

  this.setState({ loading: true });

  const endpoint = webConfigJSON.clientJobPostDetailsRequestEndPoint;

  // Construct request body
  const requestBody: any = {};

  // Add filters to the request body
  if (this.state.search) {
    requestBody.query = this.state.search;
  }
  
  if (this.state.selectedFilters.duration) {
    requestBody.duration = this.state.selectedFilters.duration;
  }
  if (this.state.selectedFilters.budget) {
    requestBody.budget_ranges = this.state.selectedFilters.budget;
  }
  
  if (this.state.selectedFilters.proposal) {
    requestBody.num_proposals = this.state.selectedFilters.proposal;
  }
  if (this.state.selectedFilters.country) {
    requestBody.country = this.state.selectedFilters.country;
  }
  if (this.state.selectedOption) {
    requestBody.most_relevant = this.state.selectedOption === "Most Recent" ? 'true' : 'false'; 
  }
  const pageSize = 10; 
  requestBody.page = this.state.currentPage || 1; 
  requestBody.limit = pageSize;
  // Add endpoint and headers
  this.clientJobPostId = ClientJobPostDetailsRequest.messageId;
  
  ClientJobPostDetailsRequest.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endpoint
  );

  ClientJobPostDetailsRequest.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header) // Stringify headers
  );

  // Stringify the request body and pass it directly
  ClientJobPostDetailsRequest.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(requestBody)
  );

  ClientJobPostDetailsRequest.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    'POST'
  );

  runEngine.sendMessage(
    ClientJobPostDetailsRequest.id,
    ClientJobPostDetailsRequest
  );
};




   fetchClientDetails = () => {
    const ClientJobPostDetailsRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      'Content-Type': webConfigJSON.dashboarContentType,
      token: this.state.authToken
    };
    

    this.clientDetailsId = ClientJobPostDetailsRequest.messageId;
    ClientJobPostDetailsRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
        `account_block/freelancers/get_freelancer_profile_online_status`
    );
    ClientJobPostDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    ClientJobPostDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );

    runEngine.sendMessage(
      ClientJobPostDetailsRequest.id,
      ClientJobPostDetailsRequest
    );
  };
  
  handleCheckboxChange = (filterType: string, value: string) => {
    this.setState(
      (prevState) => {
        const currentSelections = prevState.selectedFilters[filterType] || [];
        const isSelected = currentSelections.includes(value);
  
        return {
          selectedFilters: {
            ...prevState.selectedFilters,
            [filterType]: isSelected
              ? currentSelections.filter((item:any) => item !== value) 
              : [...currentSelections, value],                 
          },
        };
      },
      () => {
        this.fetchClientJobPostDetails();
      }
    );
  };
  
  

  handleClearFilter = () => {
    this.setState({
      selectedFilters: {
        proposal: '',
        duration: '',
        budget: '',
        country: ''
      }
    });
  };
  
  

  handleSavePopup = () => {
    this.setState({jobSavePopup: !this.state.jobSavePopup})
  }

  handlePageChange = (_event: any, page: any) => {
    this.setState({ currentPage: page }, () => {
      this.fetchClientJobPostDetails(); 
    });
  };
  
  
  handleSendProposalClick=async(jobId:number|string) => {
    await storage.set("currentProposalJobId",jobId)
    this.props.navigation.navigate('SendProposals')  
}

handlePage = (event: any, page: number) => {
  this.setState(
    {currentPage: page
      
    });  
};

handleSaveforLaterJobs = (jobId:string|number) => {
  this.handleSavePopup()
  const saveforLaterJobs = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  const header = {
    "Content-Type":  webConfigJSON.dashboarContentType,
  };
  const body = {
    "favourites": {
       "favourable_type":"BxBlockPosts::Post",
        "favourable_id":jobId
    },
          "token": this.state.authToken
  }
  this.saveforLaterJobsId = saveforLaterJobs.messageId;
  saveforLaterJobs.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    webConfigJSON.saveLaterEndPoint
  );
  saveforLaterJobs.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );
  saveforLaterJobs.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(body)
  )
  saveforLaterJobs.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    webConfigJSON.addMilestoneMethodeType
  );
  runEngine.sendMessage(saveforLaterJobs.id, saveforLaterJobs);
}

handleProjectBudget = async () =>{
  this.setState(
    {
      toggleProjectBudget : !this.state.toggleProjectBudget
    })
} 
handleProposals= async() => {
  this.setState({toggleProposals : !this.state.toggleProposals})
}
handleEnglishLevel = async() => {
  this.setState({toggleEnglishLanguage : !this.state.toggleEnglishLanguage})
}

handleOtherLanguage = async() => {
  this.setState({toggleOtherLanguage : !this.state.toggleOtherLanguage})
}
handleScopeOfWork = async()=>{
  this.setState({ 
    toggelScopeOfWork: !this.state.toggelScopeOfWork})
} 

fetchShowFavourites = () => {
    
  const showFavouritesRequest = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  const header = {
    'Content-Type': webConfigJSON.dashboarContentType,
    "token": this.state.authToken
   
  };
  

  this.showFavouritesId = showFavouritesRequest.messageId;
  showFavouritesRequest.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    webConfigJSON.showFavouritesRequestEndPoint 
  );
  showFavouritesRequest.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  showFavouritesRequest.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    webConfigJSON.dashboarApiMethodType
  );

  runEngine.sendMessage(showFavouritesRequest.id, showFavouritesRequest);
};
getCountriesList = async () => {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  const header = {
    "Content-Type": webConfigJSON.dashboarContentType,
    token: this.state.authToken
  };


  this.countryListID = requestMessage.messageId
  console.log("id:id =>",this.countryListID);
  

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    'bx_block_posts/nations'
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
   "GET"
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
};

  // Customizable Area End

  async receive(_from: string, _message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === _message.id) {
      const requestCallId = _message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const errorBlock = ()=>{
        const errorMessage = _message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorMessage);
      }
      if (this.clientJobPostId === requestCallId) {
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        

        if (responseJson) {
          const pageSize = 10; // Fixed value
         const totalPages = Math.ceil(responseJson.total_count / pageSize);
          //calculate no of pages
          this.setState({
            totalPages,
            clientJobPostData: responseJson?.jobs_list?.data,
            loading: false
          });          
        } else {
          errorBlock();
        }
      }
      if (this.showFavouritesId === requestCallId) {
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson.data) {
          this.setState({ favouritesJobIds: responseJson.data.map((item: { attributes: { favourable_id: any; }; })=>item.attributes.favourable_id) });
          
        } else {
          errorBlock();
        }
      }
      if(this.saveforLaterJobsId == requestCallId){
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        if (responseJson) {
          this.setState(prevState => ({
            favouritesJobIds: [...prevState.favouritesJobIds, responseJson.favourable_id]
          }));
          
        } else {
          errorBlock();
        }

      }
      if (this.clientDetailsId === requestCallId) {
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if(responseJson){
          this.setState({ clientDetails: responseJson })
        }
      }
      if(this.countryListID === requestCallId){
        const responseJson = _message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );        
        if(responseJson){
          const countries = responseJson.map((country: any) => country?.name);
          this.setState({ countryList: countries });
        }
      }

    }
    

    // Customizable Area End
  }
}
