import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import storage from "framework/src/StorageProvider";
import { ActiveProposalData, InvitationToInterviewData, SubmittedProposalsData } from "./FreelancerOffersController.web";
// Customizable Area Start

// Customizable Area End

export const webConfigJSON = require("./config.js");

export interface Props {
  navigation: any;
  // Customizable Area Start
  proposalType?:number|string
  activeProposalData:ActiveProposalData| null
  submittedProposalsData:SubmittedProposalsData|null
  invitationToInterviewData:InvitationToInterviewData|null
  // Customizable Area End
}
interface S {
  // Customizable Area Start
  anchorEl: null | HTMLElement;
  search: string;
  authToken:string;
  proposalType:number|string
  activeProposalData:ActiveProposalData| null
  submittedProposalsData:SubmittedProposalsData|null
  invitationToInterviewData:InvitationToInterviewData|null
  selectedTab:number|string;
  searchSelect:number;
  proposalsData: any;
  archiveProposalsData: any;
  offerProposalsData: any;
  activeTab: any;
  loadingActiveProposals: boolean;
  activeProposalCurrentPage: number
  // Customizable Area End
}
interface SS { }

export default class FullWidthJobsTabController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  proposalsApiCallID : string = "";
  activeProposalDataId: string = "";
  offersDataId:  string = ""
  archivedProposalsDataId: string = ""; 
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      anchorEl: null,
      search: '',
      authToken:"",
      activeProposalData: null,
      submittedProposalsData:null,
      invitationToInterviewData:null,
      proposalType:0,
      selectedTab:0,
      searchSelect:0,
      proposalsData: [],
      archiveProposalsData: [],
      offerProposalsData: [],
      activeTab: 1,
      loadingActiveProposals: false,
      activeProposalCurrentPage: 1
    };
    
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getDashboardData();
    // Customizable Area Start
    this.setState({
      authToken: await storage.get("authToken"),
    })
    if(this.props.navigation?.history?.location?.state?.tab){
      this.setState({selectedTab:this.props.navigation?.history?.location?.state?.tab})
    }
    this.fetchProposals()
    this.handleActiveProposalData()
    this.handleOffersData()
    // Customizable Area End
  }

  getDashboardData(): boolean {
    // Customizable Area Start
    // Customizable Area End
    return true;
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const requestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      const errorBlock = ()=>{
        const errorMessage = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorMessage);
      }
      if (this.proposalsApiCallID === requestCallId) {
        const responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
      
        if (responseJson) {
          this.setState({ proposalsData: responseJson });
        } else {
          errorBlock();
        }
      }
      this.handleArchivedProposalRes(requestCallId,message)
      this.handleOfferProposalRes(requestCallId,message)
    }
    // Customizable Area End
  }
  // Customizable Area Start

  
  handleArchivedProposalRes = (requestCallId: string,message:Message) => {
    if (this.archivedProposalsDataId === requestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ archiveProposalsData: responseJson });
      } 
    }
  }

  handleOfferProposalRes = (requestCallId: string,message:Message) => {
    if (this.offersDataId === requestCallId) {
      const responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson) {
        this.setState({ offerProposalsData: responseJson });
      } 
    }
  }

  handleTabChange = (event: any, newValue: number) => {
    this.setState({ activeTab: newValue });
  };

  handleChange = (event:any, newValue: any) => {
    this.setState({ searchSelect: newValue });
  };

  fetchProposals = () => {
    const ClientJobPostDetailsRequest = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
  
    const header = {
      'Content-Type': webConfigJSON.dashboarContentType,
      token: this.state.authToken
    };
  
    let endpoint = `bx_block_proposal_generation/proposal_generations`
  
    this.proposalsApiCallID = ClientJobPostDetailsRequest.messageId;
  
    ClientJobPostDetailsRequest.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endpoint
    );
  
    ClientJobPostDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
  
    ClientJobPostDetailsRequest.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      webConfigJSON.dashboarApiMethodType
    );
  
    runEngine.sendMessage(
      ClientJobPostDetailsRequest.id,
      ClientJobPostDetailsRequest
    );
  };

  handleOffersData =()=>{

    const header = {
      "token": this.state.authToken,
      "Content-Type": webConfigJSON.validationApiContentType,
    };
 
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
);
    this.offersDataId=requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/my_proposals/offers_details?type=active`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),'GET');

    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true
  }

  handleActiveProposalData =() => {
    const header = {
      "Content-Type": webConfigJSON.validationApiContentType,
      token: this.state.authToken,
    };
   const requestMessage=new Message(getName(MessageEnum.RestAPIRequestMessage));
  
    this.archivedProposalsDataId=requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_posts/my_proposals/archived_proposals/`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    ); 
    runEngine.sendMessage(requestMessage.id, requestMessage)
    return true;
  };
  // Customizable Area End
}
