export const avtara = require('../assets/avtara.svg');
export const online = require('../assets/online.svg');
export const checkIcon = require('../assets/checkbox-svg.svg');
export const Group9 = require('../assets/Images/Group9.svg');
export const profile = require('../assets/Images/profile.png');
export const Dots = require('../assets/Images/dots.svg');
export const bluering = require('../assets/Images/bluerimg.svg');
export const checkbox = require('../assets/Images/checkbox.svg');
export const screenshot = require('../assets/Images/Screenshot.svg');
export const facebook = require('../assets/Images/fb.svg');
export const Message = require('../assets/Images/iconMessage.png');
export const insta = require('../assets/Images/insta.svg');
export const linkdin = require('../assets/Images/linkedin.svg');
export const twitter = require('../assets/Images/twitter.svg');
export const monitor = require('../assets/Images/monitor.svg');
export const angleleft = require('../assets/Images/angleleft.png');
export const bulbicon = require('../assets/Images/bulbicon.svg');
export const clientProfile = require('../assets/clientProfile.png');
export const checkImage = require('../assets/checkIcon.png');
export const attached = require('../assets/attached.png');
export const userProfile = require('../assets/userProfile.png');
export const successImg = require('../assets/Images/successfullCompleted.svg');
export const paymentCard = require('../assets/paymentCard.svg');
export const selectedPaymentCard = require('../assets/selectedPaymentCard.svg');
export const payPal = require('../assets/payPal.svg');
export const googlePay = require('../assets/googlePay.svg');
export const masterCard = require('../assets/Mastercard-logo.svg');
export const backArrow = require('../assets/Path.svg');
export const paymentSuccess = require('../assets/paymentSuccess.svg');
export const paymentFail = require('../assets/paymentFailed.svg');
export const homepage1 = require('../assets/Images/homepage1.svg');
export const homepage2 = require('../assets/Images/homepage2.svg');
export const homepage3 = require('../assets/Images/homepage3.svg');
export const homepage4 = require('../assets/Images/homepage4.svg');
export const attactfile = require('../assets/Images/attactfile.svg');
export const uploadIcon = require('../assets/Images/uploadIcon.svg');
export const uploadIcon2 = require('../assets/Images/uploadIcon2.svg');
export const uploadIcon3 = require('../assets/Images/uploadIcon3.svg');
export const uploadIcon4 = require('../assets/Images/uploadIcon4.svg');
export const uploadIcon5 = require('../assets/Images/uploadIcon5.svg');
export const uploadIcon6 = require('../assets/Images/uploadIcon6.svg');
export const infoIcon = require('../assets/Images/infoIcon.svg');
export const autoCompleteSvgIcon = require('../assets/Images/autocompleteIcon.svg');
export const locationIcon = require('../assets/location-pin.png');
export const closeIcon = require('../assets/icon-park-outline_close-one.png');
export const messageIcon = require('../assets/Images/messageIcon.svg');

export const blankchat = require("../assets/blankchat.png");
export const img1 = require("../assets/img1.png");
export const img2 = require("../assets/img2.png");
export const sendmsg = require("../assets/sendmsg.png");
export const searchJob1 = require('../assets/Images/searchJob1.svg');
export const calendar = require('../assets/Images/calendar.svg');
export const locationPin = require('../assets/Images/locationPin.svg');
export const verified = require('../assets/Images/verified.svg');
export const dot = require('../assets/Images/dot.svg');
export const jobProfileAvatar = require('../assets/Images/job-profile-avatar.svg');
export const shap = require("../assets/shape.png");
export const oval = require('../assets/Images/oval.svg');
export const dotted = require('../assets/Images/dotted.svg');
export const ovalright = require('../assets/Images/round.svg');
export const edit = require('../assets/edit.svg')
export const addicon = require('../assets/addicon.png')
export const uploadIcon1 = require('../assets/uploadIcon1.svg')
export const cancel = require("../assets/cancel.png");
export const connectedBtn = require("../assets/connectedBtn.png");
export const paypal = require("../assets/paypal.png");
export const deleteBtn = require("../assets/delete.png");
export const PaymentCard = require("../assets/Payment card.png");
export const ArrowIcon = require("../assets/Frame.png");




